import clsx from 'clsx';
import { ReactNode, memo } from 'react';

import { ParagraphWrapper } from '~/ui/atoms';

import { EmptyStateIllustration } from '~/assets/illustrations';

type P = {
  CallToAction?: ReactNode;
  heading?: string;
  hint: string | string[];
  Illustration?: ReactNode | null;
} & ComponentStyling;

export const EmptyState = memo<P>(
  ({
    CallToAction = null,
    Illustration = <EmptyStateIllustration />,
    className,
    heading,
    hint,
    style,
  }) => (
    <div
      className={clsx(
        'mx-auto flex h-full max-h-96 w-full max-w-lg flex-col justify-center gap-y-2 p-4 text-center',
        className,
      )}
      style={style}
    >
      {Illustration}
      {heading && <h2 className="text-xl text-tigaText-lightest">{heading}</h2>}
      <div className="text-sm text-tigaText-lighter">
        {Array.isArray(hint) ? (
          <ParagraphWrapper paragraphs={hint} wrapIn="div" />
        ) : (
          hint
        )}
      </div>
      {CallToAction}
    </div>
  ),
);
