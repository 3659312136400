import { createSlice } from '@reduxjs/toolkit';

const wizardSlice = createSlice({
  name: 'wizard',

  initialState: {
    companyAccounts: [],
    companies: [],
    users: [],
  },

  reducers: (create) => ({
    clear: create.reducer((state, param) => {
      state.companyAccounts = [];
      state.companies = [];
      state.users = [];
    }),

    addCompanyAccount: create.reducer((state, param) => {
      const { payload } = param;
      state.companyAccounts = [...state.companyAccounts, payload];
    }),

    updateCompanyAccount: create.reducer((state, param) => {
      const { payload } = param;
      const companyAccounts = [...state.companyAccounts];
      const index = companyAccounts.findIndex(
        (companyAccount) => companyAccount.id === payload.id,
      );

      if (index !== -1) {
        companyAccounts[index] = payload;
      }

      state.companyAccounts = companyAccounts;
    }),

    addCompany: create.reducer((state, param) => {
      const { payload } = param;
      state.companies = [...state.companies, payload];
    }),

    updateCompany: create.reducer((state, param) => {
      const { payload } = param;
      const companies = [...state.companies];
      const index = companies.findIndex((company) => company.id === payload.id);

      if (index !== -1) {
        companies[index] = payload;
      }

      state.companies = companies;
    }),

    addUser: create.reducer((state, param) => {
      const { payload } = param;
      state.users = [...state.users, payload];
    }),
  }),
});

const { actions, reducer } = wizardSlice;
export const {
  addCompany,
  addCompanyAccount,
  addUser,
  clear,
  updateCompany,
  updateCompanyAccount,
} = actions;
export default reducer;
