import { LOADING_STATE } from '../constants/loadingState';
import CompanyAccount from '../models/CompanyAccount';
import {
  replaceCompanyAccounts,
  setCompanyAccountsLoading,
} from '../redux/companyAccountsSlice';
import store from '../redux/store';
import { promiseHandler } from '../utils/promiseHandler';
import { companyAccountClient, rootClientStaging } from './apiClient';
import ToastService from './toast.service';

class CompanyAccountService {
  async getAllCompanyAccounts() {
    return companyAccountClient
      .get('/')
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.company_accounts?.map((item) => {
          const companyAccount = new CompanyAccount();
          companyAccount.init(item);

          return companyAccount;
        });
      })
      .catch((err) => {
        ToastService.httpError(
          [ToastService.MESSAGE.COMPANY_ACCOUNT_LOAD_FAILED],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async getCompanyAccount(companyAccountId) {
    return companyAccountClient
      .get(`/${companyAccountId}`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return null;
        }

        const companyAccount = new CompanyAccount();
        companyAccount.init(data);

        return companyAccount;
      })
      .catch((err) => {
        ToastService.httpError(
          ['Firmen-Account konnte nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async createNewCompanyAccount(body) {
    return companyAccountClient
      .post(`/`, body)
      .then(({ data }) => {
        ToastService.info(`Firmen-Account ${body?.name} wurde angelegt.`);

        return data;
      })
      .catch((err) => {
        ToastService.httpError(
          [`Firmen-Account ${body?.name} konnte nicht angelegt werden.`],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async updateCompanyAccount(companyAccountId, body) {
    return companyAccountClient
      .put(`/${companyAccountId}`, body)
      .catch((err) => {
        ToastService.httpError(
          [ToastService.MESSAGE.COMPANY_ACCOUNT_UPDATE_FAILED],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async syncWithStaging(companyAccountId) {
    const body = { company_account_id: companyAccountId };

    return rootClientStaging.post('/sync/trigger', body).catch((err) => {
      ToastService.httpError(
        ['Firmen-Account konnte nicht auf Staging erstellt werden.'],
        err.response,
      );
      return Promise.reject(err);
    });
  }

  async loadCompanyAccounts() {
    // Prevent loading company accounts again when they are already loading or have already been loaded
    if (
      store.getState().companyAccounts?.companyAccountsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshCompanyAccounts();
  }

  async refreshCompanyAccounts() {
    store.dispatch(setCompanyAccountsLoading(LOADING_STATE.LOADING));

    const [companyAccounts, err] = await promiseHandler(
      this.getAllCompanyAccounts(),
    );

    if (err) {
      store.dispatch(setCompanyAccountsLoading(LOADING_STATE.FAILED));
      return;
    }

    store.dispatch(replaceCompanyAccounts(companyAccounts));
  }
}

export default new CompanyAccountService();
