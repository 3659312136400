import { AppBar, Avatar, Menu, MenuItem, Toolbar } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useAuth } from 'oidc-react';
import { MouseEvent, useState } from 'react';

import { Button } from '~/ui/molecules/Button';
import { LanguageSwitcher } from '~/ui/molecules/LanguageSwitcher';
import { HotkeysLegend } from '~/ui/organisms/HotkeysLegend';

import { Config } from '~/Config';
import { cn } from '~/utils';

type P = {
  className?: string;
  mainNavOpen?: boolean;
};

export const HeaderBar = ({ className, mainNavOpen }: P) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const queryClient = useQueryClient();

  const auth = useAuth();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChangePassword = () => {
    window.open(
      'https://login.vestigas.com/auth/realms/vestigas/account/password',
      '_blank',
      'noopener,noreferrer',
    );
  };

  const handleLogout = () => {
    // Clear react-query cache on logout
    queryClient.clear();

    auth.signOutRedirect({
      post_logout_redirect_uri: Config.redirectUrl,
    });
  };

  return (
    <AppBar
      position="absolute"
      className={cn(
        'flex !flex-row items-center justify-end border-b !bg-white pl-6 pr-4 !shadow-none',
        className,
      )}
    >
      <Toolbar disableGutters={!mainNavOpen} className={clsx('gap-4 !px-0')}>
        <HotkeysLegend />
        <LanguageSwitcher className="text-tigaText-default" />
        <Button
          variant="outlined"
          onClick={handleMenuOpen}
          className="gap-2 !rounded-full !border-tigaBorder-default !py-1 !pl-1 !pr-4"
        >
          <Avatar
            src={'/assets/profile_image_3.jpg'}
            alt={'current user name'}
            sx={{
              height: 40,
              width: 40,
            }}
          />
          <span className="text-tigaText-default">
            {auth.userData?.profile.name}
          </span>
        </Button>
        <Menu
          id="account-menu"
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          data-testid="account-menu"
          BackdropProps={{
            style: {
              backdropFilter: 'none',
              backgroundColor: 'transparent',
            },
          }}
          keepMounted
        >
          <MenuItem
            onClick={handleChangePassword}
            data-testid="change-password-action"
          >
            {'Passwort ändern'}
          </MenuItem>
          <MenuItem onClick={handleLogout} data-testid="logout-action">
            {'Ausloggen'}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
