import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '~/ui/molecules/Button';

import { cn, i18n } from '~/utils';

type ActionProps = ButtonProps;

const primaryActionDefault: ActionProps = {
  color: 'primary',
  size: 'large',
  text: i18n.t('common.button.primary.default'),
  title: undefined,
  type: 'button',
  variant: 'contained',
};

const secondaryActionDefault: ActionProps = {
  text: i18n.t('common.button.secondary.default'),
  variant: 'text',
};

const tertiaryActionDefault: ActionProps = {
  color: 'error',
  style: { marginLeft: 'auto' },
  text: i18n.t('common.button.tertiary.default'),
  variant: 'outlined',
};

type ActionsRowProps = PropsWithChildren<{
  onCancel?: () => void;
  primaryAction?: ActionProps;
  secondaryAction?: ActionProps;
  secondaryActionComponent?: ReactNode;
  shouldBeHidden?: boolean;
  tertiaryAction?: ActionProps;
}> &
  ComponentStyling;

/**
 * Renders a row with primary, secondary, and tertiary actions.
 *
 * @param {PropsWithChildren<{
 *   onCancel?: () => void;
 *   primaryAction?: ActionProps;
 *   secondaryAction?: ActionProps;
 *   shouldBeHidden?: boolean;
 *   style?: React.CSSProperties;
 *   tertiaryAction?: ActionProps;
 * }> & ComponentStyling} props - The props for the ActionsRow component.
 * @return {JSX.Element} The rendered actions row component.
 */
export const ActionsRow = ({
  children,
  className,
  onCancel,
  primaryAction,
  secondaryAction,
  secondaryActionComponent,
  shouldBeHidden = false,
  style,
  tertiaryAction,
}: ActionsRowProps) => {
  const { t } = useTranslation();

  const primaryActionProps: ActionProps = {
    ...primaryActionDefault,
    ...(primaryAction ?? {}),
  };
  if (primaryActionProps.disabled) {
    primaryActionProps.title = [
      primaryActionProps.title,
      t('common.button.disabledTooltip'),
    ]
      .filter(Boolean)
      .join(' ');
    primaryActionProps.style = {
      ...primaryActionProps.style,
      cursor: 'not-allowed',
    }; // Does not work with Material UI b/c of pointer-events: none;
  }

  const { ...secondaryActionProps }: ActionProps = {
    ...secondaryActionDefault,
    onClick: onCancel,
    ...(secondaryAction ?? {}),
  };

  const { ...tertiaryActionProps }: ActionProps = {
    ...tertiaryActionDefault,
    ...(tertiaryAction ?? {}),
  };

  return (
    <div
      className={cn('flex w-full items-center gap-4 p-4', className)}
      style={style}
    >
      <Button {...primaryActionProps} />
      {onCancel && !shouldBeHidden && <Button {...secondaryActionProps} />}
      {secondaryActionComponent && !shouldBeHidden && secondaryActionComponent}
      {tertiaryAction && <Button {...tertiaryActionProps} />}
      {children}
    </div>
  );
};
