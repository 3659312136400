import { MainNavItemProps } from '~/ui/molecules/MainNav/types';

import { ROUTES } from './config';
import { RedirectRouteType, RouteName, RouteType } from './types';

/**
 * Filter function to remove redirect routes from a list of routes.
 */
const removeRedirectRoutesFilterFunc = ({
  omitFromNav,
}: RouteType | RedirectRouteType) => !omitFromNav;

/**
 * Builds a flat dictionary of routes recursively.
 *
 * @param {object} dictionary - The dictionary object to store routes.
 * @param {object} route - The current route object to add to the dictionary.
 * @return {object} The updated dictionary with the added routes.
 */
const buildRoutesDictionary = (
  dictionary: Record<RouteName, RouteType>,
  route: RouteType | RedirectRouteType,
) => {
  if (!('name' in route)) {
    return dictionary;
  }

  const { children, name } = route;
  dictionary[name] = route;

  if (children) {
    children.forEach((childRoute: RouteType | RedirectRouteType) =>
      buildRoutesDictionary(dictionary, childRoute),
    );
  }

  return dictionary;
};

const routesDictionary = ROUTES.reduce(
  buildRoutesDictionary,
  {} as Record<RouteName, RouteType>,
);

/**
 * Returns the entire route object for a given route name.
 */
export const getRouteById = (routeId: RouteName) => routesDictionary[routeId];

/**
 * Builds a navigation configuration based on the provided list of routes.
 * Returns the top level routes or nested routes as well based on the showChildren flag.
 *
 * @param {Array} routes - An array of route IDs.
 * @param {boolean} showChildren - A flag indicating whether to show children routes.
 * @return {Array} An array of navigation items.
 */
export const buildNavConfig = (routes: RouteName[], showChildren?: boolean) =>
  routes.flatMap((routeId) => {
    let items: MainNavItemProps[] = [];
    const route = getRouteById(routeId);

    if (route) {
      const routePath = route.path;

      if (showChildren) {
        items = items.concat({
          // @ts-expect-error // TODO: fix this
          children: route?.children
            ?.filter(removeRedirectRoutesFilterFunc)
            ?.map(({ icon, label, name, path }) => ({
              icon,
              label,
              name,
              path,
            })),
          icon: route.icon,
          label: route.label,
          name: routeId,
          path: routePath,
        });
      } else {
        const { icon, label } = route;
        items.push({
          icon,
          label,
          name: routeId,
          path: routePath,
        });
      }
    }

    return items;
  });
