import cloneDeep from 'lodash/cloneDeep';

import Address from './Address';

export default class Company {
  constructor() {
    this.id = '';
    this.name = '';
    this.companyAccountId = '';
    this.address = new Address();
    this.logo = null; // Only used for wizard
  }

  init(legalOrganization, companyAccountId) {
    this.id = legalOrganization?.id ?? '';
    this.name = legalOrganization?.name ?? '';
    this.companyAccountId =
      companyAccountId ?? legalOrganization?.company_account_id ?? '';
    this.address.init(legalOrganization?.address);
    this.logo = null;
  }

  clone(company) {
    // TODO: Doesn't this create a copy by reference, which can lead to the most disgusting bugs? Use proper cloning.
    this.id = company?.id;
    this.name = company?.name;
    this.companyAccountId = company?.companyAccountId;
    this.address = company?.address ? cloneDeep(company.address) : this.address;
    this.logo = company?.logo;
  }
}
