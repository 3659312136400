import camelcaseKeys from 'camelcase-keys';
import ky from 'ky';
import snakecaseKeys from 'snakecase-keys';
import urlcat from 'urlcat';

import { Environment } from '~/constants/environments';

import { Config } from '../Config';
import AuthService from './auth.service';

const accessToken = localStorage.getItem('accessToken');

const trailingSlashRegex = /\/+$/;

/**
 * Get the API base URL based on the environment.
 *
 * @param {string} api - The API endpoint
 * @param {Environment | 'current'} env - The environment (e.g. 'staging')
 * @return {string} The base URL for the given environment and API
 */
const getBaseURL = (api: string, env: Environment | 'current') => {
  if (env === 'staging') {
    return urlcat(Config.stagingApiUrl, api);
  }

  return urlcat(Config.apiUrl, api);
};

/**
 * Creates a ky instance with a base URL based on the provided API path and environment.
 * The instance is configured to
 * - include credentials in requests
 * - refresh the access token on 401 (unauthorized) responses
 * - remove trailing slashes from URLs
 * - convert request bodies to snake_case
 * - convert response data to camelCase (if case converter is enabled)
 * - disable default timeouts and retries as those are handled by react-query
 *
 * @param {string} apiPath - The API endpoint path
 * @param {string} [env='current'] - The environment (e.g. 'staging')
 * @param {boolean} [withCaseConverter=false] - Whether to enable case conversion
 * @return {ky} The configured ky instance
 */
const createKyInstance = (
  apiPath = '',
  env: Environment | 'current' = 'current',
  withCaseConverter = false,
) => {
  const baseURL = getBaseURL(apiPath, env);

  const kyInstance = ky.create({
    prefixUrl: baseURL,
    timeout: false, // Disable timeouts (managed by react-query)
    retry: 0, // Disable retries (managed by react-query)
    hooks: {
      beforeRequest: [
        (request, options) => {
          // Add Authorization header
          const headers = new Headers(request.headers);
          headers.set('Authorization', `Bearer ${accessToken}`);

          // Remove trailing slashes from the URL, because the VESTIGAS API breaks at requests with trailing slashes.
          const url = request.url.replace(trailingSlashRegex, '');

          // Convert the request body to snake_case
          let body = options.body;
          if (withCaseConverter && body && typeof body === 'string') {
            const jsonBody = JSON.parse(body);
            body = JSON.stringify(snakecaseKeys(jsonBody, { deep: true }));
          }

          // Create a new request with the modified URL and headers
          return new Request(url, { ...options, headers, body });
        },
      ],
      afterResponse: [
        async (request, _options, response) => {
          if (response.status === 401) {
            try {
              // Attempt to refresh the token
              const newTokens = await AuthService.refreshTokens();
              localStorage.setItem('accessToken', newTokens.accessToken);

              // Retry the original request with the new token
              const retryHeaders = new Headers(request.headers);
              retryHeaders.set(
                'Authorization',
                `Bearer ${newTokens.accessToken}`,
              );
              const retryRequest = new Request(request, {
                headers: retryHeaders,
              });
              const retryResponse = await ky(retryRequest);

              return retryResponse;
            } catch (refreshError) {
              console.error('Token refresh error:', refreshError);
              throw response; // Re-throw the error to propagate the 401 error if refresh fails.
            }
          }

          // Convert the response data to camelCase
          if (
            withCaseConverter &&
            response.headers.get('content-type')?.includes('application/json')
          ) {
            const responseBody = await response.json();
            const camelCasedBody = camelcaseKeys(responseBody, { deep: true });

            return new Response(JSON.stringify(camelCasedBody), {
              headers: response.headers,
              status: response.status,
              statusText: response.statusText,
            });
          }

          return response;
        },
      ],
      beforeError: [
        (error) => {
          console.error('Ky error:', error);
          throw error; // Re-throw the error to propagate it to the calling function and handle it there.
        },
      ],
    },
  });

  return kyInstance;
};

/**
 * ky instance for working with the VESTIGAS API in the current environment.
 */
export const vestigasApi = createKyInstance('', 'current', true);

/**
 * ky instance for working with the VESTIGAS API in the STAGING environment.
 */
export const vestigasApiStaging = createKyInstance('', 'staging', true);
