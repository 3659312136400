import { LOADING_STATE } from '../constants/loadingState';
import Company from '../models/Company';
import InvoiceCheckDelay from '../models/InvoiceCheckDelay';
import SupplierAlertConfig from '../models/SupplierAlertConfig';
import {
  replaceCompanies,
  replaceSupplierAlertConfigs,
  setCompaniesLoading,
  setSupplierAlertConfigsLoading,
} from '../redux/companiesSlice';
import store from '../redux/store';
import { promiseHandler } from '../utils/promiseHandler';
import {
  companyAddressClient,
  companyClient,
  vestigasAdminClient,
  vestigasAdminClientStaging,
} from './apiClient';
import ToastService from './toast.service';

class CompanyService {
  async getAllCompanies() {
    return companyClient
      .get('/all')
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.companies.map((item) => {
          const company = new Company();
          company.init(item);

          return company;
        });
      })
      .catch((err) => {
        ToastService.httpError(
          ['Firmen-Daten konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async createNewCompany(body) {
    return companyClient
      .post('/', body)
      .then(({ data }) => {
        ToastService.info([`Firma ${body?.name} wurde angelegt.`]);
        return data?.id;
      })
      .catch((err) => {
        ToastService.httpError(
          [`Firma ${body?.name} konnte nicht angelegt werden.`],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async updateCompany(id, body) {
    return companyClient
      .put(`/${id}`, body)
      .then(({ data }) => {
        ToastService.info([`Firma ${body?.name} wurde aktualisiert.`]);
        return data?.id;
      })
      .catch((err) => {
        ToastService.httpError(
          [`Firma ${body?.name} konnte nicht aktualisiert werden.`],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async getApiKeys(companyId) {
    return vestigasAdminClient
      .get(`/company/${companyId}/api_key`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.api_keys.map(({ key }) => key);
      })
      .catch((err) => {
        ToastService.httpError(
          ['API Keys konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async createNewApiKey(companyId) {
    return vestigasAdminClient
      .post(`/company/${companyId}/api_key`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return;
        }

        navigator.clipboard
          .writeText(data.api_key)
          .then((response) => {
            ToastService.info(
              'API Key wurde erstellt und in die Zwischenablage kopiert.',
            );
          })
          .catch((err) => {
            ToastService.info(
              'API Key wurde erstellt, konnte aber nicht in die Zwischenablage kopiert werden.',
            );
          });
      })
      .catch((err) => {
        ToastService.httpError(
          ['API Key konnte nicht erstellt.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async getStagingApiKeys(companyId) {
    return vestigasAdminClientStaging
      .get(`/company/${companyId}/api_key`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.api_keys.map(({ key }) => key);
      })
      .catch((err) => {
        ToastService.httpError(
          ['Staging API Keys konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async createNewStagingApiKey(companyId) {
    return vestigasAdminClientStaging
      .post(`/company/${companyId}/api_key`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return;
        }

        navigator.clipboard
          .writeText(data.api_key)
          .then(() => {
            ToastService.info([
              'Staging API Key wurde erstellt und in die Zwischenablage kopiert.',
            ]);
          })
          .catch((err) => {
            ToastService.info([
              'Staging API Key wurde erstellt, konnte aber nicht in die Zwischenablage kopiert werden.',
            ]);
          });
      })
      .catch((err) => {
        ToastService.httpError(
          ['Staging API Key konnte nicht erstellt.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async getInvoiceCheckDelay(companyId) {
    return vestigasAdminClient
      .get(`/company/${companyId}/invoice_check_delay`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return null;
        }

        const invoiceCheckDelay = new InvoiceCheckDelay();
        invoiceCheckDelay.init(data);

        return invoiceCheckDelay;
      })
      .catch((err) => {
        ToastService.httpError(
          ['Zeitverzögerung der Rechnungsprüfung konnte nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async updateInvoiceCheckDelay(companyId, body) {
    return vestigasAdminClient
      .post(`/company/${companyId}/invoice_check_delay`, body)
      .catch((err) => {
        ToastService.httpError(
          [
            'Zeitverzögerung der Rechnungsprüfung konnte nicht geändert werden.',
          ],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async getInvoiceReceivers(companyId) {
    return companyClient
      .get(`/${companyId}/invoice_receivers`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.invoice_receivers;
      })
      .catch((err) => {
        ToastService.httpError(
          ['Rechnungsempfänger konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  // async addInvoiceReceivers(companyId, invoiceReceivers) {
  //   return companyClient
  //     .post(`/${companyId}/invoice_receivers`, invoiceReceivers)
  //     .catch((err) => {
  //       ToastService.httpError(
  //         ['Rechnungsempfänger konnten nicht hinzugefügt werden.'],
  //         err.response,
  //       );
  //       return Promise.reject(err);
  //     });
  // }

  /**
   *
   * @see https://app.dev.vestigas.com/redoc#tag/Company-Addresses/operation/create_company_addresses_company_address_post
   */
  async createCompanyAddressMapping(body) {
    return companyAddressClient
      .post('/', body)
      .then(() => {
        ToastService.info(
          `Address-Mapping für Firma ${body?.name} wurde hinzugefügt.`,
        );
      })
      .catch((err) => {
        ToastService.httpError(
          ['Address-Mapping konnte nicht hinzugefügt werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  /**
   *
   * @see https://app.dev.vestigas.com/redoc#tag/VESTIGAS-Admin/operation/get_company_vadmin_settings_vadmin_company__id__vadmin_settings_get
   */
  async getDirectlySendInvoicesTo(companyId) {
    return vestigasAdminClient
      .get(`/company/${companyId}/vadmin_settings`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.directly_send_invoices_to ?? [];
      })
      .catch((err) => {
        ToastService.httpError(
          [
            'Firmen für automatischen Rechnungsversand konnten nicht geladen werden.',
          ],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  /**
   *
   * @see https://app.dev.vestigas.com/redoc#tag/VESTIGAS-Admin/operation/set_directly_send_invoices_to_vadmin_company__company_uuid__directly_send_invoices_to_put
   */
  async updateDirectlySendInvoicesTo(companyId, directlySendInvoicesTo) {
    const body = { ids: directlySendInvoicesTo };

    return vestigasAdminClient
      .put(`/company/${companyId}/directly_send_invoices_to`, body)
      .catch((err) => {
        ToastService.httpError(
          'Firmen für automatischen Rechnungsversand konnten nicht aktualisiert werden.',
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async getCompanyLogo(companyId) {
    if (!companyId) {
      return Promise.reject(
        new Error(`Failed to load company logo. Company id: ${companyId}`),
      );
    }

    return companyClient.get(`/${companyId}/logo`).then(({ data }) => data);
  }

  async uploadCompanyLogo(companyId, logo) {
    return companyClient.put(`/${companyId}/logo`, logo, {
      headers: {
        'Content-Type': logo.type, // Has to be image/jpeg or image/png
      },
    });
  }

  async deleteCompanyLogo(companyId) {
    return companyClient.delete(`/${companyId}/logo`);
  }

  async deleteTestDlns(buyerId, dryRun) {
    const body = {
      buyer_id: buyerId,
      dry_run: dryRun,
    };

    return vestigasAdminClient
      .post('/delete_test_dlns', body)
      .then(({ data }) => data.count_mains);
  }

  loadCompanies = async () => {
    // to not load companies again when they are already loading or have already been loaded
    if (
      store.getState().companies?.companiesLoading !== LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    await this.refreshCompanies();
  };

  refreshCompanies = async () => {
    store.dispatch(setCompaniesLoading(LOADING_STATE.LOADING));

    const [companies, err] = await promiseHandler(this.getAllCompanies());

    if (err) {
      store.dispatch(setCompaniesLoading(LOADING_STATE.FAILED));
      return;
    }

    store.dispatch(replaceCompanies(companies));
  };

  getAllSupplierAlertConfigs() {
    return companyClient
      .get(`/sending_companies`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.map((item) => {
          const supplierAlertConfig = new SupplierAlertConfig();
          supplierAlertConfig.init(item);

          return supplierAlertConfig;
        });
      })
      .catch((err) => {
        ToastService.httpError(
          ['Supplier Alert Configs konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  loadSupplierAlertConfigs = async () => {
    // to not load data again when they are already loading or have already been loaded
    if (
      store.getState().companies?.supplierAlertConfigsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    await this.refreshSupplierAlertConfigs();
  };

  refreshSupplierAlertConfigs = async () => {
    store.dispatch(setSupplierAlertConfigsLoading(LOADING_STATE.LOADING));

    const [supplierAlertConfigs, err] = await promiseHandler(
      this.getAllSupplierAlertConfigs(),
    );

    if (err) {
      store.dispatch(setSupplierAlertConfigsLoading(LOADING_STATE.FAILED));
      return;
    }

    store.dispatch(replaceSupplierAlertConfigs(supplierAlertConfigs));
  };

  getSupplierAlertConfig(companyId) {
    companyClient
      .get(`/${companyId}/alert_config`)
      .then(({ data, status }) => {
        if (status !== 200) {
          return null;
        }

        const supplierAlertConfig = new SupplierAlertConfig();
        supplierAlertConfig.init(data);

        return supplierAlertConfig;
      })
      .catch((err) => {
        ToastService.httpError(
          ['Supplier Alert Config konnte nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  updateSupplierAlertConfig(companyId, body) {
    return companyClient
      .put(`/${companyId}/alert_config`, body)
      .catch((err) => {
        ToastService.httpError(
          ['Supplier Alert Config konnte nicht geändert werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  deleteSupplierAlertConfig(companyId) {
    return companyClient.put(`/${companyId}/alert_config`, {}).catch((err) => {
      ToastService.httpError(
        ['Supplier Alert Config konnte nicht entfernt werden.'],
        err.response,
      );
      return Promise.reject(err);
    });
  }
}

export default new CompanyService();
