import iso3311a2 from 'iso-3166-1-alpha-2';

export default class Address {
  constructor() {
    this.streetName = '';
    this.buildingNumber = '';
    this.postCode = '';
    this.city = '';
    this.country = 'DE';
  }

  init(address) {
    this.streetName = address?.street_name ?? '';
    this.buildingNumber = address?.building_number ?? '';
    this.postCode = address?.post_code ?? '';
    this.city = address?.city ?? '';
    this.country = address?.country ?? '';
  }

  static getConcatenatedAddress(address) {
    const streetName = address?.streetName;
    const buildingNumber = address?.buildingNumber;
    const postCode = address?.postCode;
    const city = address?.city;
    const country = address?.country;

    return (
      (streetName ?? '') +
      (streetName && buildingNumber ? ' ' : '') +
      (buildingNumber ?? '') +
      (streetName || buildingNumber ? ', ' : '') +
      (postCode ?? '') +
      (postCode && city ? ' ' : '') +
      (city ?? '') +
      (postCode && city && country ? ', ' + country : '')
    );
  }

  static getCountryCodes() {
    return iso3311a2.getData();
  }

  static getCountryCodeOptions() {
    this.countryCodes = Address.getCountryCodes();
    return Object.keys(this.countryCodes).map((code) => {
      return {
        id: code,
        name: code + ': ' + this.countryCodes[code],
        searchString: [code, this.countryCodes[code]].join(';').toLowerCase(),
      };
    });
  }
}
