import { camelCase, pascalCase, snakeCase } from 'change-case';
import slugifyRaw from 'slugify';

/**
 * Convert a string to camelCase.
 */
export const toCamelCase = (str?: string | null) =>
  typeof str === 'string' ? camelCase(str) : str;

/**
 * Convert a string to snake_case.
 */
export const toSnakeCase = (str?: string | null) =>
  typeof str === 'string' ? snakeCase(str) : str;

/**
 * Convert a string to PascalCase.
 */
export const toPascalCase = (str?: string | null) =>
  typeof str === 'string' ? pascalCase(str) : str;

/**
 * Normalize a string for string comparison purposes.
 * Converts the string to lowercase and removes leading/trailing whitespace.
 *
 * @param str - The string to normalize.
 * @returns The normalized string.
 */
export const normalize = (str: string) => (str ? str.toLowerCase().trim() : '');

/**
 * Convert a string to a slug by replacing spaces with underscores and making it lowercase.
 * Uses the `slugify` package under the hood.
 * @param text - The input string to be slugified.
 * @returns The slugified string.
 */
export const slugify = (text: string) =>
  slugifyRaw(text, {
    lower: true,
    replacement: '_',
    strict: true,
  });

/**
 * Truncate a string to a specified length and appends an omission sign if necessary.
 *
 * @param str - The string to truncate.
 * @param length - The maximum length of the truncated string. Defaults to 16.
 * @param omissionSign - The sign to append to the truncated string. Defaults to '[…]'.
 * @returns The truncated string.
 */
export const truncate = (str: string, length = 16, omissionSign = '[…]') => {
  if (!str || str.length <= length) {
    return str;
  }

  if (!omissionSign) {
    return str.slice(0, length);
  }

  return str.slice(0, length - omissionSign.length) + omissionSign;
};
