import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '../constants/loadingState';

const companiesSlice = createSlice({
  name: 'companies',

  initialState: {
    companies: [],
    companiesLoading: LOADING_STATE.NOT_LOADED,
    supplierAlertConfigs: [],
    supplierAlertConfigsLoading: LOADING_STATE.NOT_LOADED,
  },

  reducers: (create) => ({
    replaceCompanies: create.reducer((state, param) => {
      const { payload } = param;

      state.companies = [...payload];
      state.companiesLoading = LOADING_STATE.SUCCEEDED;
    }),

    setCompaniesLoading: create.reducer((state, param) => {
      const { payload } = param;

      state.companiesLoading = payload;
    }),

    addCompany: create.reducer((state, param) => {
      const { payload } = param;
      state.companies = [...state.companies, payload];
    }),

    replaceSupplierAlertConfigs: create.reducer((state, param) => {
      const { payload } = param;

      state.supplierAlertConfigs = [...payload];
      state.supplierAlertConfigsLoading = LOADING_STATE.SUCCEEDED;
    }),

    setSupplierAlertConfigsLoading: create.reducer((state, param) => {
      const { payload } = param;

      state.supplierAlertConfigsLoading = payload;
    }),
  }),
});

const { actions, reducer } = companiesSlice;
export const {
  addCompany,
  replaceCompanies,
  replaceSupplierAlertConfigs,
  setCompaniesLoading,
  setSupplierAlertConfigsLoading,
} = actions;
export default reducer;
