import { ReactNode } from 'react';

import { ParagraphWrapper } from '~/ui/atoms';

import { cn } from '~/utils';

type P = {
  callToAction?: ReactNode;
  illustration?: ReactNode;
  message: string | string[];
  title: string;
} & ComponentStyling;

export const ErrorPage = ({
  callToAction,
  className,
  illustration,
  message,
  style,
  title,
}: P) => (
  <div className={cn('flex h-full flex-col', className)} style={style}>
    <div className="flex flex-col items-center gap-4">
      {title && (
        <h1 className="line-clamp-1 text-balance text-center text-4xl tracking-tight text-tigaSemantic-error">
          {title}
        </h1>
      )}
      <ParagraphWrapper
        paragraphs={message}
        className="text-tigaSemantic-error"
      />
      {callToAction}
      {illustration}
    </div>
  </div>
);
