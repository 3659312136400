import { createBrowserRouter } from 'react-router-dom';

import { AppLayout } from '~/ui/layouts/AppLayout';

import { RootErrorBoundary } from './components';
import { ROUTES } from './config';

export const router = createBrowserRouter(
  [
    {
      element: <AppLayout />,
      errorElement: <RootErrorBoundary />,
      children: ROUTES,
    },
  ],
  {
    future: {
      // opt in to future changes to ease migration
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      // v7_prependBasename: true,
      v7_relativeSplatPath: true,
      unstable_skipActionErrorRevalidation: true,
    },
  },
);
