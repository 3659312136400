import { i18n } from '~/utils';

export const PARTIES = [
  'buyer',
  'carrier',
  'recipient',
  'seller',
  'supplier',
  'trader',
  'issuer',
] as const;

export const PARTY_NAMES = {
  buyer: i18n.t('assetReparsing.party.buyer'),
  carrier: i18n.t('assetReparsing.party.carrier'),
  issuer: i18n.t('assetReparsing.party.issuer'),
  recipient: i18n.t('assetReparsing.party.recipient'),
  seller: i18n.t('assetReparsing.party.seller'),
  supplier: i18n.t('assetReparsing.party.supplier'),
  trader: i18n.t('assetReparsing.party.trader'),
} as const;

export const SEARCH_PARAM_ASSET_ID = 'assetId'; // searchParam used to persist the selected asset

export const SHOW_TASKS_LIST_FILTER_THRESHOLD = 5; // minimum number of tasks in list to show filter

/**
 * Mapping of key data to their respective paths in the delivery note object.
 */
export const REPARSING_DATA_PATHS_MAP = {
  buyer: 'transaction.agreement.buyer',
  carrier: 'transaction.delivery.shipFrom.consignment.carrier',
  issuer: 'header.issuer',
  recipient: 'transaction.delivery.shipTo',
  seller: 'transaction.agreement.seller',
  supplier: 'transaction.delivery.shipFrom',
  trader: 'transaction.agreement.trader',
  lineItems: 'transaction.logisticsPackage[0].lineItem', // logisticsPackage ALWAYS has only one item with all the data
} as const;
