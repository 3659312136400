export default class CompanyIdentifier {
  constructor() {
    this.id = '';
    this.issuerCompanyId = '';
    this.identifyingText = '';
    this.identifiedCompanyId = '';
  }

  init(companyIdentifier) {
    this.id = companyIdentifier?.id ?? '';
    this.issuerCompanyId = companyIdentifier?.issuer_company_id ?? '';
    this.identifyingText = companyIdentifier?.identifying_text ?? '';
    this.identifiedCompanyId = companyIdentifier?.identified_company_id ?? '';
  }

  clone(companyIdentifier) {
    // TODO: Doesn't this create a copy by reference, which can lead to the most disgusting bugs? Use proper cloning.
    this.id = companyIdentifier?.id;
    this.issuerCompanyId = companyIdentifier?.issuerCompanyId;
    this.identifyingText = companyIdentifier?.identifyingText;
    this.identifiedCompanyId = companyIdentifier?.identifiedCompanyId;
  }
}
