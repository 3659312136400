'use client';

import { ComponentType, useCallback } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { ZodObject } from 'zod';

import { FormRow, Icon } from '~/ui/atoms';
import { Button } from '~/ui/molecules/Button';
import { EmptyState } from '~/ui/molecules/EmptyState';

import { cn, i18n } from '~/utils';

import { ListSortButtons } from './ListSortButtons';

const defaultAddButtonText = i18n.t('common.listManager.addItemButton.text');
const defaultEmptyStateText = i18n.t('common.listManager.emptyState.text');

type ListItem = {
  context?: object;
  formMethods: UseFormReturn;
  index: number;
  length?: number;
  namePrefix?: string;
};

type ListManagerProps = {
  addButtonText?: string;
  appendNewItems?: boolean;
  canAdd?: boolean;
  canDelete?: boolean;
  canSort?: boolean;
  context?: Record<string, unknown>;
  deleteButtonClassName?: string;
  emptyStateText?: string | string[] | null;
  fieldArrayConfig: {
    name: string;
  };
  formMethods: UseFormReturn;
  isMultiLineForm?: boolean;
  itemActionsClassName?: string;
  ListItemComponent: ComponentType<ListItem>;
  namePrefix?: string;
  newItemTemplate: object;
  schema?: ZodObject<any>;
  sortButtonsClassName?: string;
} & ComponentStyling;

/**
 * Renders a list manager component that allows adding, deleting, and reordering a list of form elements
 * inside a form managed with react-hook-form.
 *
 * @see https://react-hook-form.com/docs/usefieldarray
 *
 * @param {Object} props - The component props.
 * @param {React.ComponentType<ListItem>} props.ListItemComponent - The component to render for each list item.
 * @param {string} [props.addButtonText=defaultAddButtonText] - The text to display on the add button.
 * @param {boolean} [props.appendNewItems=false] - Whether to append new items to the end of the list or prepend them to the beginning.
 * @param {boolean} [props.canAdd] - Whether the add button should be displayed.
 * @param {boolean} [props.canDelete] - Whether the delete buttons should be displayed for each list item.
 * @param {boolean} [props.canSort] - Whether the sort buttons should be displayed for each list item.
 * @param {string} [props.className] - Additional CSS class names for the component.
 * @param {Record<string, unknown>} [props.context={}] - Additional context to pass to the ListItemComponent.
 * @param {string} [props.deleteButtonClassName] - Additional CSS class names for the delete buttons.
 * @param {string | string[] | null} [props.emptyStateText=defaultEmptyStateText] - The text to display when the list is empty.
 * @param {Object} props.fieldArrayConfig - The configuration object for the field array.
 * @param {string} props.fieldArrayConfig.name - The name of the field array.
 * @param {UseFormReturn} props.formMethods - The form methods from react-hook-form.
 * @param {boolean} [props.isMultiLineForm=false] - Whether the form is multi-line.
 * @param {string} [props.itemActionsClassName] - Additional CSS class names for the item actions container.
 * @param {string} [props.namePrefix=''] - The prefix to add to the field names.
 * @param {Object} props.newItemTemplate - The template for new items.
 * @param {string} [props.sortButtonsClassName] - Additional CSS class names for the sort buttons.
 * @param {Object} [props.style] - Additional inline styles for the component.
 * @return {JSX.Element} The rendered list manager component.
 */
export const ListManager = ({
  ListItemComponent,
  addButtonText = defaultAddButtonText,
  appendNewItems = false,
  canAdd,
  canDelete,
  canSort,
  className,
  context = {},
  deleteButtonClassName,
  emptyStateText,
  fieldArrayConfig,
  formMethods,
  isMultiLineForm,
  itemActionsClassName,
  namePrefix = '',
  newItemTemplate,
  schema,
  sortButtonsClassName,
  style,
}: ListManagerProps) => {
  const { control } = formMethods;
  const { name: fieldArrayName, ...restFieldArrayConfig } = fieldArrayConfig;
  const { append, fields, move, prepend, remove } = useFieldArray({
    control,
    name: `${namePrefix}${fieldArrayName}`, // Each useFieldArray has its own state update and thus needs a unique name!
    ...restFieldArrayConfig,
  });

  const emptyState =
    emptyStateText === null || emptyStateText === ''
      ? null
      : (emptyStateText ?? defaultEmptyStateText);

  const hasMultipleItems = fields.length > 1;
  const hasManyItems = fields.length >= 3;

  const handleAddItem = useCallback(() => {
    if (appendNewItems) {
      append(newItemTemplate);
    } else {
      prepend(newItemTemplate);
    }

    setTimeout(() => {
      formMethods.trigger(); // Validate form
    }, 50);
  }, [append, appendNewItems, prepend, newItemTemplate, formMethods]);

  return (
    <div className={cn('flex flex-1 flex-col gap-4', className)} style={style}>
      {canAdd && (
        <div>
          <Button
            type="button"
            variant="outlined"
            icon={<Icon metaphor="add" />}
            text={addButtonText}
            onClick={handleAddItem}
            className="ml-auto"
          />
        </div>
      )}
      {fields.map((field, i) => {
        const np = [namePrefix, `${fieldArrayConfig.name}.${i}.`].join('');

        return (
          <FormRow key={`${field.id}${i}`} vAlign="top" className="flex-nowrap">
            <ListItemComponent
              formMethods={formMethods}
              namePrefix={np}
              length={fields.length}
              index={i}
              context={context}
              schema={schema}
            />
            {
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              ((canSort && hasMultipleItems) || canDelete) && (
                <div
                  className={cn(
                    'flex items-start gap-2',
                    {
                      'items-center': canSort && !isMultiLineForm,
                    },
                    itemActionsClassName,
                  )}
                >
                  {canSort && hasMultipleItems && (
                    <ListSortButtons
                      className={cn('text-right', sortButtonsClassName)}
                      move={move}
                      index={i}
                      length={fields.length}
                    />
                  )}
                  {canDelete && (
                    <Button
                      className={cn('px-2', deleteButtonClassName)}
                      buttonStyle="secondary"
                      size="small"
                      onClick={() => remove(i)}
                      icon={<Icon metaphor="delete" />}
                      title={'Delete this item'}
                    />
                  )}
                </div>
              )
            }
          </FormRow>
        );
      })}
      {canAdd && hasManyItems && (
        <div>
          <Button
            type="button"
            variant="outlined"
            icon={<Icon metaphor="add" />}
            text={addButtonText}
            onClick={handleAddItem}
            className="ml-auto"
          />
        </div>
      )}
      {!fields?.length && emptyState && <EmptyState hint={emptyState} />}
    </div>
  );
};
