import { List, Stack } from '@mui/material';

import { RouteName } from '~/modules/router/types';
import { buildNavConfig } from '~/modules/router/utils';

import { LogoSigneOnly, LogoWithText } from '~/ui/atoms';

import { MainNavItem } from './MainNavItem';
import { MainNavItemList } from './MainNavItemList';

const MAIN_NAV_ITEMS: RouteName[] = [
  'home',
  'clients',
  'supplierConnections',
  'endpoints',
  'assetReparsing',
  'aiParserReview',
];

const MainNavItems = buildNavConfig(MAIN_NAV_ITEMS, true);

interface P {
  isOpen?: boolean;
}

export const MainNav = ({ isOpen = false }: P) => (
  <>
    <Stack direction="row" spacing={2}>
      <div className="w-full px-4 py-2">
        {isOpen ? <LogoWithText /> : <LogoSigneOnly />}
      </div>
    </Stack>
    <List>
      {MainNavItems.map((navItem) => {
        if (navItem.children) {
          return <MainNavItemList key={navItem.name} {...navItem} />;
        }

        return <MainNavItem key={navItem.name} {...navItem} />;
      })}
    </List>
  </>
);
