import { useEffect } from 'react';

import AuthService, { getJwtPayload } from '~/services/auth.service';
import CompanyService from '~/services/company.service';
import CompanyAccountService from '~/services/companyAccount.service';
import CompanyIdentifierService from '~/services/companyIdentifier.service';
import DataExchangeService from '~/services/dataExchange.service';
import { UserService } from '~/services/user.service';

export const AppInitializer = ({ children }) => {
  const loggedIn = () => {
    try {
      return getJwtPayload();
    } catch (e) {
      return false;
    }
  };

  const isAdmin = () => {
    const jwt = getJwtPayload();
    const isAdmin = jwt.preferred_username === 'vestigas-admin';

    return isAdmin;
  };

  // Query all information that should be in global state.
  const loadData = async () => {
    CompanyAccountService.loadCompanyAccounts();
    CompanyIdentifierService.loadCompanyIdentifiers();
    UserService.loadUsers();

    // Companies are needed to initialize the data exchanges.
    await CompanyService.loadCompanies();
    await CompanyService.loadSupplierAlertConfigs();

    DataExchangeService.loadDataExchangeCompanies();
    DataExchangeService.loadDataExchanges();
  };

  useEffect(() => {
    if (!loggedIn()) {
      return;
    }

    if (!isAdmin()) {
      // Check if logout is already in process to prevent infinite loop of page refreshes.
      // AuthService.isLogoutUrl
      const searchParams = new URLSearchParams(document.location.search);
      for (const [key, value] of searchParams.entries()) {
        if (key === 'logout' && value !== 'true') {
          AuthService.logout();
        }
      }

      return;
    }

    loadData();
  }, []);

  return children;
};
