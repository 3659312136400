import { ReactElement, ReactNode, memo, useCallback } from 'react';

import { TooltipComponent } from './TooltipComponent';
import { TooltipController } from './TooltipController';
import { Point, TooltipPlacement } from './tooltipPlacement';
import { DetectType, Trigger } from './Trigger';

type TooltipProps = {
  autoPlacement?: boolean;
  children: ReactElement;
  closeOnClick?: boolean;
  content: ReactNode;
  detect?: DetectType;
  id?: string;
  onOpen?: () => void;
  placement?: TooltipPlacement;
  targetMargin?: Point;
  timeOut?: number;
  triggerClose?: boolean;
};

export const Tooltip = memo<TooltipProps>(
  ({
    autoPlacement = true,
    children,
    closeOnClick = true,
    content,
    detect = 'hover',
    id = 'tooltip',
    onOpen,
    placement = 'top',
    targetMargin = { x: 12, y: 12 },
    timeOut,
    triggerClose = false,
  }) => {
    const handleStateChange = useCallback(
      (isOpen: boolean) => {
        if (isOpen && onOpen) {
          onOpen();
        }
      },
      [onOpen],
    );

    if (!content) {
      return children;
    }

    return (
      <TooltipController
        id={id}
        closeOnClick={closeOnClick}
        detect={detect}
        returnState={handleStateChange}
        timeOut={timeOut}
        triggerClose={triggerClose}
        placement={placement}
        targetMargin={targetMargin}
        autoPlacement={autoPlacement}
      >
        <Trigger>{children}</Trigger>
        <TooltipComponent /*
        // @ts-expect-error // TODO: figure out how to best set default for arrow params */
          arrowParams={{}}
        >
          {content}
        </TooltipComponent>
      </TooltipController>
    );
  },
);
