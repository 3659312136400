import {
  Certificate,
  CertificateAuthority,
  CertificateSubject,
  ConcreteComponent,
  DeliveryMeasure,
  DocumentLine,
  LineItem,
  Product,
  Quantity,
  Settlement,
  WeighingWeight,
  Weight,
} from '~/types/deliveryNoteLineItem';

import {
  ACCOUNT_STRUCTURE,
  LEGAL_ORGANIZATION_STRUCTURE,
  PARTY_STRUCTURE,
  SETTLEMENT_STRUCTURE,
} from './deliveryNote';

export const CERTIFICATE_SUBJECT_STRUCTURE: CertificateSubject = {
  legalOrganization: LEGAL_ORGANIZATION_STRUCTURE,
  subjectId: undefined,
};

export const CERTIFICATE_AUTHORITY_STRUCTURE: CertificateAuthority = {
  authorityId: undefined,
  legalOrganization: LEGAL_ORGANIZATION_STRUCTURE,
};

export const CERTIFICATE_STRUCTURE: Certificate = {
  certificateAuthority: CERTIFICATE_AUTHORITY_STRUCTURE,
  certificateSubject: CERTIFICATE_SUBJECT_STRUCTURE,
  comment: undefined,
  expiresAt: undefined,
  issuedAt: undefined,
  norms: [undefined],
  notBefore: undefined,
  performanceDeclaration: undefined,
  type: undefined,
  usage: undefined,
};

export const CONCRETE_COMPONENT_STRUCTURE: ConcreteComponent = {
  chlorideClass: undefined,
  chlorideContentQuantity: undefined,
  chlorideContentQuantityUnit: undefined,
  comment: undefined,
  components: [{}],
  consistency: undefined,
  exposureClass: undefined,
  grossDensityClass: undefined,
  largestGrain: undefined,
  lastValidation: undefined,
  moistureClass: undefined,
  posteriorAdded: [{}],
  processabilityTime: undefined,
  returnedMaterialQuantity: undefined,
  returnedMaterialQuantityUnit: undefined,
  strengthClass: undefined,
  strengthDevelopment: undefined,
  type: undefined,
  usage: undefined,
  waterCementRatio: undefined,
};

export const PRODUCT_STRUCTURE: Product = {
  batch: undefined,
  certificate: CERTIFICATE_STRUCTURE,
  comment: undefined,
  concrete: CONCRETE_COMPONENT_STRUCTURE,
  constructionComponent: undefined,
  constructionPlan: undefined,
  description: undefined,
  ean: undefined,
  manufacturer: PARTY_STRUCTURE,
  name: undefined,
  productionDate: undefined,
  sellerAssignedId: undefined,
};

export const LINE_ITEM_SETTLEMENT_STRUCTURE: Settlement = {
  salesAccount: ACCOUNT_STRUCTURE,
  payableAccount: ACCOUNT_STRUCTURE,
};

export const WEIGHT_STRUCTURE: Weight = {
  measure: undefined,
  weight: undefined,
};

export const QUANTITY_STRUCTURE: Quantity = {
  quantity: undefined,
  quantityType: undefined,
};

export const DELIVERY_MEASURE_STRUCTURE: DeliveryMeasure = {
  measure: undefined,
  value: undefined,
};

export const WEIGHING_WEIGHT_STRUCTURE: WeighingWeight = {
  date: undefined,
  id: undefined,
  ...WEIGHT_STRUCTURE,
};

export const DOCUMENT_LINE_STRUCTURE: DocumentLine = {
  note: {
    content: {
      grossWeight: WEIGHING_WEIGHT_STRUCTURE,
      tareWeight: WEIGHING_WEIGHT_STRUCTURE,
      weighingParty: {},
      weighingPerson: {},
    },
  },
};

export const LINE_ITEM_AGREEMENT_STRUCTURE: LineItem['agreement'] = {
  buyerOrder: undefined,
  sellerOrder: undefined,
};

export const LINE_ITEM_DELIVERY_STRUCTURE: LineItem['delivery'] = {
  actualDelivery: DELIVERY_MEASURE_STRUCTURE,
  handling: {},
  netWeight: WEIGHT_STRUCTURE,
  productUnit: QUANTITY_STRUCTURE,
  remainingDelivery: DELIVERY_MEASURE_STRUCTURE,
  requestedDelivery: DELIVERY_MEASURE_STRUCTURE,
  shippingMarks: null,
};

/**
 * Object structure of a delivery note line item.
 */
export const DELIVERY_NOTE_LINE_ITEM_STRUCTURE: LineItem = {
  additionalPartyData: {},
  agreement: LINE_ITEM_AGREEMENT_STRUCTURE,
  delivery: LINE_ITEM_DELIVERY_STRUCTURE,
  documentLine: DOCUMENT_LINE_STRUCTURE,
  id: undefined,
  product: PRODUCT_STRUCTURE,
  settlement: SETTLEMENT_STRUCTURE,
};
