export default class SupplierAlertConfig {
  constructor() {
    this.companyId = '';
    this.numDays = 1;
    this.onlyWorkDays = false;
    this.delayedDays = 0;
  }

  init(supplierAlertConfig) {
    this.companyId = supplierAlertConfig?.id ?? '';
    this.numDays = supplierAlertConfig?.num_days ?? 1;
    this.onlyWorkDays = supplierAlertConfig?.only_work_days ?? false;
    this.delayedDays = supplierAlertConfig?.delayed_days ?? 0;
  }

  clone(supplierAlertConfig) {
    // TODO: Doesn't this create a copy by reference, which can lead to the most disgusting bugs? Use proper cloning.
    this.companyId = supplierAlertConfig?.companyId;
    this.numDays = supplierAlertConfig?.numDays;
    this.onlyWorkDays = supplierAlertConfig?.onlyWorkDays;
    this.delayedDays = supplierAlertConfig?.delayedDays;
  }
}
