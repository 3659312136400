/**
 * @deprecated This goes completely away when using react-query
 */
export const LOADING_STATE = {
  NOT_LOADED: 'not-loaded',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  OUTDATED: 'outdated',
};
