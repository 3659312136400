import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

interface P {
  alignLeft?: boolean;
  size?: number;
  title?: string;
  white?: boolean;
}

export const Spinner = ({ alignLeft, size = 20, title, white }: P) => (
  <div
    className={clsx([
      'h-full',
      {
        'flex w-full items-center justify-center': !alignLeft,
        'text-white': white,
      },
    ])}
  >
    <CircularProgress size={size} style={white ? { color: 'white' } : {}} />
    {title ? <div className="ml-2">{title}</div> : null}
  </div>
);
