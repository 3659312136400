import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui/atoms';
import { Button } from '~/ui/molecules/Button';

import { useHotkeysContext } from '~/hooks';

const specialKeys = (hotkey) =>
  ['meta', 'ctrl', 'alt', 'shift', 'mod'].reduce(
    (acc, cur) => (hotkey[cur] ? [...acc, cur] : acc),
    [],
  );

/**
 * Renders a component that resembles the keyboard key it represents.
 *
 * @param {string} key - The key to be rendered.
 * @return {JSX.Element} The keyboard key component.
 */
const KeyboardKey = (key: string) => (
  <span
    key={key}
    className="rounded-md border border-tigaText-default px-2 py-0.5 uppercase"
  >
    {key === 'meta' ? '⌘' : key}
  </span>
);

/**
 * Renders an icon button that opens a modal with a list of available hotkeys.
 *
 * @return {JSX.Element} The rendered hotkeys legend.
 */
export const HotkeysLegend = ({ className, style }: ComponentStyling) => {
  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation();

  const { hotkeys } = useHotkeysContext();

  if (!hotkeys.map(({ description }) => description).some(Boolean)) {
    // No descriptions to display.
    return null;
  }

  return (
    <>
      <Button
        icon={<Icon metaphor="keyboard" />}
        onClick={() => setOpen(true)}
        className={clsx({ '!hidden opacity-0': !hotkeys.length }, className)}
        style={style}
      />
      <Dialog onClose={() => setOpen(false)} open={isOpen}>
        <DialogTitle>{t('hotkey.legend.title')}</DialogTitle>
        <DialogContent>
          <table className="table-auto border-separate border-spacing-y-1">
            <tbody>
              {hotkeys.map((hotkey) => {
                if (!hotkey.description) {
                  // No description to display.
                  return null;
                }

                const keys = [...specialKeys(hotkey), ...(hotkey.keys ?? [])];

                return (
                  <tr key={keys.join('')} className="py-0.5">
                    <td className="space-x-1 whitespace-nowrap pr-4">
                      {keys.map(KeyboardKey)}
                    </td>
                    <td>{hotkey.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    </>
  );
};
