import {
  Add,
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  AutoMode,
  Business,
  Cancel,
  CancelRounded,
  Check,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  CloseFullscreen,
  ContentCopy,
  DataObject,
  Delete,
  Edit,
  ExpandMore,
  FileUpload,
  FolderSpecial,
  Home,
  Http,
  Info,
  Keyboard,
  Loop,
  MoreHoriz,
  OpenInFull,
  Person,
  PersonAddAltOutlined,
  Place,
  Remove,
  Replay,
  RotateRight,
  Search,
  Star,
  Sync,
  WarningRounded,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
} from '@mui/icons-material';

import { IconMetaphor } from './constants';

export const getIcon = (metaphor: IconMetaphor) => {
  switch (metaphor) {
    case 'add':
      return Add;

    case 'address':
      return Place;

    case 'arrowBack':
      return ArrowBack;

    case 'arrowDown':
      return ArrowDownward;

    case 'arrowForward':
      return ArrowForward;

    case 'arrowUp':
      return ArrowUpward;

    case 'cancel':
      return Cancel;

    case 'cancelRounded':
      return CancelRounded;

    case 'check':
      return Check;

    case 'checkCircle':
      return CheckCircle;

    case 'chevronLeft':
      return ChevronLeft;

    case 'chevronRight':
      return ChevronRight;

    case 'clear':
      return Clear;

    case 'close':
      return Close;

    case 'company':
      return Business;

    case 'copy':
      return ContentCopy;

    case 'dataExchange':
      return Loop;

    case 'dataObject':
      return DataObject;

    case 'delete':
      return Delete;

    case 'edit':
      return Edit;

    case 'endpoint':
      return Http;

    case 'expand':
      return ExpandMore;

    case 'folderSpecial':
      return FolderSpecial;

    case 'fullscreenClose':
      return CloseFullscreen;

    case 'fullscreenOpen':
      return OpenInFull;

    case 'home':
      return Home;

    case 'info':
      return Info;

    case 'keyboard':
      return Keyboard;

    case 'more':
      return MoreHoriz;

    case 'person':
      return Person;

    case 'personAdd':
      return PersonAddAltOutlined;

    case 'processWithAI':
      return AutoMode;

    case 'remove':
      return Remove;

    case 'replay':
      return Replay;

    case 'rotateRight':
      return RotateRight;

    case 'search':
      return Search;

    case 'star':
      return Star;

    case 'sync':
      return Sync;

    case 'uploadFile':
      return FileUpload;

    case 'warning':
      return WarningRounded;

    case 'zoomFit':
      return ZoomOutMap;

    case 'zoomIn':
      return ZoomIn;

    case 'zoomOut':
      return ZoomOut;
  }
};
