import { ReactElement, cloneElement, forwardRef, memo } from 'react';

export type DetectType = 'click' | 'hover' | 'hover-hold' | 'hover-interact';

type TriggerType = {
  children: ReactElement;
  closeMenu?: () => any;
  detect?: DetectType;
  openMenu?: () => any;
  timeOutFunc?: () => any;
};

export const Trigger = memo<TriggerType>(
  forwardRef(({ children, closeMenu, detect, openMenu, timeOutFunc }, ref) => {
    const props: any = {
      onTouchEnd: openMenu,
      ref,
    };

    switch (detect) {
      case 'click':
        props.onClick = openMenu;
        break;

      case 'hover':
        props.onMouseEnter = openMenu;
        props.onMouseLeave = closeMenu;
        break;

      case 'hover-hold':
        props.onMouseEnter = openMenu;
        break;

      case 'hover-interact':
        props.onMouseEnter = openMenu;
        props.onMouseLeave = timeOutFunc;
        break;

      default:
        return null;
    }

    return cloneElement(children, props);
  }),
);
