import {
  PARTIES,
  PARTY_NAMES,
  REPARSING_DATA_PATHS_MAP,
} from '~/modules/asset-reparsing/constants';
import {
  PartyType,
  RawReparsingAsset,
  ReparsingProblemType,
  ReparsingTaskType,
} from '~/modules/asset-reparsing/types';
import {
  guaranteeDeliveryNoteLineItemStructure,
  guaranteeDeliveryNoteStructure,
} from '~/modules/delivery-notes';

import { getNestedProperty } from '~/ui/molecules/ReactHookForm';

import { datetime4datetimeInput, formatFromUTCDate, i18n } from '~/utils';

/**
 * Retrieves type of task and useful instructions for user based on the given problem type and reparsing parties.
 *
 * @param {ReparsingProblemType} problemType - The type of problem encountered.
 * @param {PartyType[]} reparsingParties - The parties involved in the reparsing.
 * @return {{ taskDescription: string[]; taskType: ReparsingTaskType }} The task details including description and type.
 */
const getTaskDetails = (
  problemType: ReparsingProblemType,
  reparsingParties: PartyType[],
): {
  taskDescription: string[];
  taskType: ReparsingTaskType;
} => {
  const problemTypeToTaskType: {
    [key in ReparsingProblemType]: ReparsingTaskType;
  } = {
    correct_ai_parser_results: 'correctAIParserResults',
    missing_parties: 'mapCompany',
  };

  const taskType = problemTypeToTaskType[problemType] ?? 'notImplemented';
  let taskDescription;

  switch (problemType) {
    case 'missing_parties':
      taskDescription = [
        i18n.t(
          'assetReparsing.taskDescription.missingParties.description.problem',
          {
            parties: reparsingParties
              .map((p) => PARTY_NAMES[p])
              .join(i18n.t('common.joinList.and')),
          },
        ),
        i18n.t(
          'assetReparsing.taskDescription.missingParties.description.task',
        ),
      ];
      break;

    case 'correct_ai_parser_results':
      taskDescription = [
        i18n.t(
          'assetReparsing.taskDescription.aiParserResults.description.problem',
        ),
        i18n.t(
          'assetReparsing.taskDescription.aiParserResults.description.task',
        ),
      ];
      return {
        taskDescription,
        taskType,
      };

    default:
      taskDescription = 'not implemented';
  }

  return {
    taskDescription: [
      i18n.t('assetReparsing.taskDescription.missingParties.description.intro'),
      taskDescription,
      i18n.t('assetReparsing.taskDescription.missingParties.description.outro'),
    ].flat(),
    taskType,
  };
};

/**
 * Picks the properties we need from the raw reparsing asset object received from the API.
 * @param {RawReparsingAsset} reparsingAsset - The reparsing asset object to destructure.
 * @return {DestructuredReparsingAsset} The destructured reparsing asset properties.
 */
const destructRawReparsingAsset = (reparsingAsset: RawReparsingAsset = {}) => {
  const reparsingData = {
    assetId: reparsingAsset?.id,
    problemType: reparsingAsset?.problemType,
    reparsingId: reparsingAsset?.reparsingId,
    reparsingParties: reparsingAsset?.parties ?? [],
  };

  const deliveryNote = guaranteeDeliveryNoteStructure(
    reparsingAsset?.parsedAsset?.body,
  );

  const lineItems = deliveryNote.transaction?.logisticsPackage[0].lineItem; // logisticsPackage ALWAYS has only one item with all the data

  return {
    ...deliveryNote, // Return the full delivery note object
    assetParties: PARTIES.reduce((acc, cur) => {
      acc[cur] = getNestedProperty(deliveryNote, REPARSING_DATA_PATHS_MAP[cur]);

      return acc;
    }, {}),
    lineItems: lineItems?.map(guaranteeDeliveryNoteLineItemStructure),
    reparsingData, // Additional reparsing data to the delivery note
  };
};

/**
 * Creates the reparsing asset object we are using in the frontend based on the data from the API response.
 * @param {RawReparsingAsset} reparsingAssetRaw - The reparsing asset data to create the object from.
 * @return {Object} The created reparsing asset object.
 */
const createReparsingAsset = (
  reparsingAssetRaw: RawReparsingAsset,
  originalDlnFileName?: string,
) => {
  const { assetParties, lineItems, reparsingData, ...deliveryNote } =
    destructRawReparsingAsset(reparsingAssetRaw);

  const taskDetails = getTaskDetails(
    reparsingData.problemType,
    reparsingData.reparsingParties,
  );

  const reparsingAsset = {
    assetParties,
    assetRaw: reparsingAssetRaw,
    dlnDate: formatFromUTCDate(
      deliveryNote?.header?.date ?? '',
      datetime4datetimeInput,
    ),
    dlnFileName: originalDlnFileName,
    lineItems,
    ...deliveryNote,
    ...reparsingData,
    ...taskDetails,
  };

  return reparsingAsset;
};

/**
 * Map an array of reparsing assets received from the backend to the data structure we use in the components.
 */
export const mapReparsingAssetsData = (reparsingAssets = []) =>
  reparsingAssets?.flatMap(
    ({ originalDlnFileName, parsedAssets }) =>
      parsedAssets?.map((asset) =>
        createReparsingAsset(asset, originalDlnFileName),
      ) ?? [],
  );
