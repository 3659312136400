import { PropsWithChildren } from 'react';

import { cn } from '~/utils';

export const ValidationError = ({
  children,
  className,
  style,
}: PropsWithChildren & ComponentStyling) => (
  <small
    className={cn('absolute text-sm text-red-700', className)}
    style={style}
  >
    {children}
  </small>
);
