export default class User {
  constructor() {
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.username = '';
    this.email = '';
    this.position = '';
    this.password = '';
    this.active = true;
    this.vestigasSupportAccount = false;
    this.companyId = '';
    this.signatureRole = User.SIGNATURE_ROLE.NONE.KEY;
    this.active = true;
  }

  init(user) {
    this.id = user?.id ?? '';
    this.firstName = user?.first_name ?? '';
    this.lastName = user?.last_name ?? '';
    this.email = user?.email ?? '';
    this.position = user?.position ?? '';
    this.active = user?.is_active ?? '';
    this.vestigasSupportAccount = user?.is_vestigas_support ?? false;
    this.companyId = user?.company_id ?? '';
    this.signatureRole = User.SIGNATURE_ROLE.NONE.KEY;
    this.active = true;
  }

  clone(user) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.username = user.username;
    this.email = user.email;
    this.position = user.position;
    this.password = user.password;
    this.active = user.active;
    this.vestigasSupportAccount = user.vestigasSupportAccount;
    this.companyId = user.companyId;
    this.signatureRole = user.signatureRole;
  }

  setVestigasSupportAccountDefaultValues() {
    this.vestigasSupportAccount = true;
  }

  static getPropsFromUserCode(userCode) {
    const email =
      'vestigas-support-' +
      userCode.replace(/ /g, '-').toLowerCase() +
      '@vestigas.com'; // Replace spaces with dashes and make lowercase
    const firstName = 'Vestigas Support';
    const lastName = userCode;

    return {
      email,
      firstName,
      lastName,
    };
  }

  static SIGNATURE_ROLE = {
    SUPPLIER: {
      KEY: 'supplier',
      STRING: 'Lieferant',
    },
    CARRIER: {
      KEY: 'carrier',
      STRING: 'Spediteur',
    },
    BUYER: {
      KEY: 'buyer',
      STRING: 'Abnehmer',
    },
    NONE: {
      KEY: 'none',
      STRING: 'Keine',
    },
  };
}
