export default class InvoiceCheckDelay {
  constructor() {
    this.delayedDays = 0;
    this.onlyIncludeWorkdays = true;
  }

  init(invoiceCheckDelay) {
    this.delayedDays = invoiceCheckDelay?.delayed_days;
    this.onlyIncludeWorkdays = invoiceCheckDelay?.only_include_workdays;
  }

  clone(invoiceCheckDelay) {
    // TODO: Doesn't this create a copy by reference, which can lead to the most disgusting bugs? Use proper cloning.
    this.delayedDays = invoiceCheckDelay.delayedDays;
    this.onlyIncludeWorkdays = invoiceCheckDelay.onlyIncludeWorkdays;
  }
}
