import { mergeDeep } from 'remeda';

import { DeliveryNote } from '~/types/deliveryNote';
import { LineItem } from '~/types/deliveryNoteLineItem';

import {
  DELIVERY_NOTE_LINE_ITEM_STRUCTURE,
  DELIVERY_NOTE_STRUCTURE,
} from '../constants';

/**
 * Function to ensure the delivery note object has the correct structure.
 * Merges the provided delivery note object into the full default structure.
 * Values in the provided object override default values.
 *
 * @param {object} partialDLNObject - The partial delivery note object to be merged.
 * @return {Partial<DeliveryNote>} The structurally complete delivery note object.
 */
export const guaranteeDeliveryNoteStructure = (
  partialDLNObject: object = {},
): Partial<DeliveryNote> =>
  mergeDeep(DELIVERY_NOTE_STRUCTURE, partialDLNObject);

/**
 * Function to ensure the line item object has the correct structure.
 * Merges the provided delivery note line item object into the full default structure.
 * Values in the provided object override default values.
 *
 * @param {object} partialLineItemObject - The partial delivery note line item object to be merged.
 * @return {Partial<DeliveryNote>} The structurally complete delivery note line item object.
 */
export const guaranteeDeliveryNoteLineItemStructure = (
  partialLineItemObject: object = {},
): Partial<LineItem> =>
  mergeDeep(DELIVERY_NOTE_LINE_ITEM_STRUCTURE, partialLineItemObject);
