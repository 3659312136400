import {
  ZodDefault,
  ZodNullable,
  ZodObject,
  ZodOptional,
  ZodString,
  ZodTypeAny,
  z,
} from 'zod';

export const DateLikeSchema = z.union([z.string(), z.date()]);

/**
 * Unwraps nested ZodOptional, ZodDefault, and ZodNullable schemas to get the core schema.
 *
 * @param {ZodTypeAny} schema - The schema to unwrap.
 * @return {ZodTypeAny} The unwrapped core schema.
 */
const unwrapSchema = (schema: ZodTypeAny): ZodTypeAny => {
  let unwrappedSchema = schema;
  while (
    unwrappedSchema instanceof ZodOptional ||
    unwrappedSchema instanceof ZodDefault ||
    unwrappedSchema instanceof ZodNullable
  ) {
    unwrappedSchema = unwrappedSchema._def.innerType;
  }
  return unwrappedSchema;
};

/**
 * Retrieves the schema for a nested field.
 * Resolves paths from react-hook-form like deliveryNote.lineItems.1.quantity.unit to retrieve the applicable schema.
 *
 * @param {ZodObject<any>} schema - The parent schema.
 * @param {string} fieldPath - The dot-separated path to the field.
 * @return {ZodTypeAny | undefined} The schema for the nested field, if found.
 */
const getNestedFieldSchema = (
  schema: ZodObject<any>,
  fieldPath: string,
): ZodTypeAny | undefined => {
  const pathSegments = fieldPath.split('.');
  let currentSchema: ZodTypeAny | undefined = schema;

  for (const segment of pathSegments) {
    if (!currentSchema) {
      return undefined;
    }

    currentSchema = unwrapSchema(currentSchema);

    if (currentSchema instanceof ZodObject && currentSchema.shape[segment]) {
      currentSchema = currentSchema.shape[segment];
    } else if (currentSchema instanceof z.ZodArray && !isNaN(Number(segment))) {
      currentSchema = currentSchema.element;
    } else {
      return undefined;
    }
  }

  return currentSchema;
};

/**
 * Determines if a field is required in a Zod schema.
 *
 * @example: isFieldRequired(zodSchema, 'lineItems.1.quantity.amount')
 *
 * @param {ZodObject<any>} schema - The Zod object schema to check.
 * @param {string} fieldPath - The dot-separated path of the field to check.
 * @return {boolean} Returns true if the field is required, false otherwise.
 */
export const isFieldRequired = (schema: ZodObject<any>, fieldPath: string) => {
  const fieldSchema = getNestedFieldSchema(schema, fieldPath);

  if (!fieldSchema) {
    // Classify field as optional if we could not find the corresponding schema.
    return false;
  }

  // Classify field as optional if the field schema itself is optional or nullable.
  if (
    fieldSchema instanceof ZodOptional ||
    fieldSchema instanceof ZodDefault ||
    fieldSchema instanceof ZodNullable
  ) {
    return false;
  }

  const unwrappedSchema = unwrapSchema(fieldSchema);

  // Check if the unwrapped schema is not wrapped in ZodOptional, ZodDefault, or ZodNullable.
  const isNotOptional = !(
    unwrappedSchema instanceof ZodOptional ||
    unwrappedSchema instanceof ZodDefault ||
    unwrappedSchema instanceof ZodNullable
  );

  /*
   * Check if the field is a string with an additional attribute (e.g. min length),
   * because '' is valid for z.string().
   */
  const isStringWithRestrictions = Boolean(
    unwrappedSchema instanceof ZodString &&
      unwrappedSchema?._def?.params &&
      Object.keys(unwrappedSchema._def.params)?.length > 0,
  );

  return isNotOptional || isStringWithRestrictions;
};
