import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/loadingState';
import { removeByKey } from '~/utils/array';

const companyAccountsSlice = createSlice({
  name: 'companyAccounts',

  initialState: {
    companyAccounts: [],
    companyAccountsLoading: LOADING_STATE.NOT_LOADED,
  },

  reducers: (create) => ({
    replaceCompanyAccounts: create.reducer((state, param) => {
      const { payload } = param;

      state.companyAccounts = [...payload];
      state.companyAccountsLoading = LOADING_STATE.SUCCEEDED;
    }),

    setCompanyAccountsLoading: create.reducer((state, param) => {
      const { payload } = param;

      state.companyAccountsLoading = payload;
    }),

    replaceCompanyAccount: create.reducer((state, param) => {
      const { payload } = param;

      const newCompanyAccounts = removeByKey(
        state.companyAccounts,
        'id',
        payload.id,
      );
      newCompanyAccounts.push(payload);

      state.companyAccounts = newCompanyAccounts;
    }),
  }),
});

const { actions, reducer } = companyAccountsSlice;

export const {
  replaceCompanyAccount,
  replaceCompanyAccounts,
  setCompanyAccountsLoading,
} = actions;

export default reducer;
