import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale, cn } from '~/utils';

const LANGUAGE_FLAG: Record<Locale, string> = {
  de: '🇩🇪',
  en: '🇺🇸',
};

/**
 * Renders a language switcher component.
 * Reads the available and current language from the i18n config and allows changing the language.
 *
 * @return {JSX.Element} The rendered language switcher component.
 */
export const LanguageSwitcher = ({ className, style }: ComponentStyling) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<Locale>(i18n.language as Locale);

  const handleLangChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const lang = e.target.value as Locale;
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <select
      onChange={handleLangChange}
      value={language}
      data-testid="language-switcher"
      className={cn('p-2', className)}
      style={style}
    >
      {i18n.languages?.map((lng: Locale) => (
        <option key={lng} value={lng}>
          {LANGUAGE_FLAG[lng]} {lng}
        </option>
      ))}
    </select>
  );
};
