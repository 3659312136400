import { Collapse, List } from '@mui/material';
import { MouseEvent, memo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MainNavItem } from './MainNavItem';
import { MainNavItemProps } from './types';

export const MainNavItemList = memo((navItem: MainNavItemProps) => {
  // Expand the list initially if a child item is active.
  const location = useLocation();
  const nestedItemIsActive = navItem?.children?.some(
    ({ path }) => path === location.pathname,
  );
  const [open, setOpen] = useState(nestedItemIsActive);

  const handleToggleNestedItems = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <>
      <MainNavItem
        {...navItem}
        onToggleNestedItems={handleToggleNestedItems}
        open={open}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List key={`${navItem.name}-list`}>
          {navItem?.children?.map((nestedNavItem) => (
            <MainNavItem key={nestedNavItem.name} {...nestedNavItem} isNested />
          ))}
        </List>
      </Collapse>
    </>
  );
});
