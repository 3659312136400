import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '../constants/loadingState';

const usersSlice = createSlice({
  name: 'users',

  initialState: {
    users: [],
    usersLoading: LOADING_STATE.NOT_LOADED,
  },

  reducers: (create) => ({
    replaceUsers: create.reducer((state, param) => {
      const { payload } = param;

      state.users = [...payload];
      state.usersLoading = LOADING_STATE.SUCCEEDED;
    }),

    setUsersLoading: create.reducer((state, param) => {
      const { payload } = param;

      state.usersLoading = payload;
    }),

    addUser: create.reducer((state, param) => {
      const { payload } = param;
      state.users = [...state.users, payload];
    }),
  }),
});

const { actions, reducer } = usersSlice;
export const { addUser, replaceUsers, setUsersLoading } = actions;
export default reducer;
