import i18nLib from './i18n';

export const i18n = i18nLib;

export * from './address';
export * from './datetime';
export * from './excel';
export * from './file';
export * from './i18n';
export * from './lang';
export * from './l10n';
export * from './lazyLoad';
export * from './object';
export * from './string';
export * from './tailwind';
export * from './url';
