import { InputLabel } from '@mui/material';
import clsx from 'clsx';
import {
  ForwardedRef,
  MutableRefObject,
  forwardRef,
  useId,
  useImperativeHandle,
  useRef,
} from 'react';
import type {
  FieldErrors,
  RegisterOptions,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { ZodObject } from 'zod';

import { isFieldRequired } from '~/utils/form';

import { ValidationError } from './ValidationError';

export type TextareaProps = {
  autoFocus?: boolean;
  autoGrow?: boolean;
  errors?: FieldErrors;
  getSelection?: () => {
    cursorStart: number;
    cursorEnd: number;
    selection?: string;
    value: string;
  };
  height?: '16' | 'full';
  label?: string;
  name: string;
  placeholder?: string;
  register: UseFormRegister<any>; // UseFormRegister<FieldValues>; // FIXME: weird type mismatch
  registerOptions?: RegisterOptions;
  schema?: ZodObject<any>;
  setFocus?: UseFormSetFocus<any>; // UseFormSetFocus<FieldValues>; // FIXME: weird type mismatch
  setValue?: UseFormSetValue<any>; // UseFormSetValue<FieldValues>; // FIXME: weird type mismatch
  watch?: UseFormWatch<any>; // UseFormWatch<FieldValues>; // FIXME: weird type mismatch
} & ComponentStyling;

export const Textarea = forwardRef(
  (
    {
      autoFocus = false,
      autoGrow = false, // autoGrow is only possible with watch prop!
      className,
      errors,
      height = '16',
      label,
      name,
      placeholder,
      register,
      registerOptions = {},
      schema,
      style,
      watch,
    }: TextareaProps,
    forwardedRef: ForwardedRef<any>,
  ) => {
    const id = useId();
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const { ref, ...restRHF } = register(name, registerOptions);

    useImperativeHandle(forwardedRef, () => ({
      getSelection: (): {
        start: number;
        end: number;
        value?: string;
      } => {
        if (inputRef.current) {
          const input = inputRef.current;
          const start = input.selectionStart;
          const end = input.selectionEnd;
          const value = input.value?.substring(start, end);

          return {
            start,
            end,
            value,
          };
        }

        return {
          start: 0,
          end: 0,
          value: '',
        };
      },
    }));

    const setRefs = (node: HTMLTextAreaElement | null) => {
      // Assigns the node to the ref object if it's not null
      if (node) {
        inputRef.current = node;

        // Setting up the ref from 'register(name)'
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          (ref as MutableRefObject<HTMLTextAreaElement | null>).current = node;
        }
      }
    };

    const hasErrors = Boolean(errors?.[name]);

    const extraProps = {} as any;
    if (placeholder) {
      extraProps.placeholder = placeholder;
    }
    if (autoFocus) {
      extraProps.autoFocus = true;
    }

    if (autoGrow && !watch) {
      // autoGrow implementation follows https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
      console.error('autoGrow is only possible with watch prop!');
    }

    return (
      <div className={clsx('relative', className)}>
        {Boolean(label) && (
          <InputLabel htmlFor={id} isRequired={isFieldRequired(schema, name)}>
            {label}
          </InputLabel>
        )}
        <div
          className={clsx(
            `font-inherit grid w-full rounded-md border border-tigaBorder-default bg-white px-3 py-2`,
            {
              'h-full': height === 'full' && !autoGrow,
              'after:font-inherit after:invisible after:col-start-1 after:col-end-2 after:row-start-1 after:row-end-2 after:whitespace-pre-wrap after:content-[attr(data-replicated-value)]':
                autoGrow,
              'focus-within:border-tigaAction-lighter hover:border-tigaAction-lighter':
                !hasErrors,
              'focus-within:border-tigaSemantic-error hover:border-tigaSemantic-error':
                hasErrors,
            },
            className,
          )}
          style={style}
          data-replicated-value={watch ? watch(name) : ''}
        >
          <textarea
            id={id}
            {...restRHF}
            ref={setRefs}
            className={clsx(
              'col-start-1 col-end-2 row-start-1 row-end-2 border-none bg-transparent placeholder:italic placeholder:text-tigaText-lighter focus:outline-none',
              {
                'h-full resize-none': height === 'full' && !autoGrow,
                'min-h-16': height === '16' && !autoGrow,
                'resize-y': height !== 'full' && !autoGrow,
                'resize-none overflow-hidden': autoGrow,
              },
            )}
            {...extraProps}
          />
          {hasErrors && (
            <ValidationError>
              {errors?.[name]?.message?.toString()}
            </ValidationError>
          )}
        </div>
      </div>
    );
  },
);
