import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import { CSSProperties, MouseEvent, forwardRef, memo, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { Icon } from '~/ui/atoms';

import { MainNavItemProps } from './types';

const ExpandItems = ({
  onClick,
  open,
}: {
  onClick?: (e: MouseEvent) => void;
  open?: boolean;
  style?: CSSProperties;
}) => {
  if (!onClick) {
    return null;
  }

  return (
    <Icon
      metaphor="expand"
      className={clsx('!transition-transform !duration-150', {
        'rotate-180': open,
      })}
    />
  );
};

export const MainNavItem = memo(
  ({
    icon,
    isNested,
    label,
    onToggleNestedItems,
    open,
    path,
  }: MainNavItemProps) => {
    const navigate = useNavigate();

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      navigate(path!);
    };

    const MUINavLink = useMemo(
      () =>
        forwardRef<HTMLAnchorElement, MainNavItemProps>(
          ({ className, ...rest }, ref) => (
            <NavLink
              {...rest}
              ref={ref}
              to={path!}
              className={({ isActive }) =>
                clsx([
                  className?.toString(),
                  {
                    'Mui-selected': isActive,
                  },
                ])
              }
              style={isNested ? { paddingLeft: 32 } : {}}
              onMouseDown={handleMouseDown}
              end // only match exact path, see https://reactrouter.com/en/main/components/nav-link#end
            />
          ),
        ),
      [path],
    );

    const props = onToggleNestedItems
      ? {
          onMouseDown: onToggleNestedItems,
        }
      : {
          component: MUINavLink,
        };

    if (isNested) {
      // @ts-expect-error // Why?
      props.style = {
        paddingLeft: 32,
      };
    }

    return (
      <ListItem {...props} button>
        {icon && (
          <ListItemIcon
            sx={{
              '.Mui-selected > &': {
                color: (theme: Theme) => theme.palette.primary.main,
              },
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
        <ExpandItems onClick={onToggleNestedItems} open={open} />
      </ListItem>
    );
  },
);
