import { LOADING_STATE } from '../constants/loadingState';
import DataExchange from '../models/DataExchange';
import DataExchangeCompany from '../models/DataExchangeCompany';
import {
  replaceDataExchangeCompanies,
  replaceDataExchanges,
  setDataExchangeCompaniesLoading,
  setDataExchangesLoading,
} from '../redux/dataExchangesSlice';
import store from '../redux/store';
import { promiseHandler } from '../utils/promiseHandler';
import { dataExchangeClient } from './apiClient';
import ToastService from './toast.service';

class DataExchangeService {
  async getAllDataExchangeCompanies(companies, supplierAlertConfigs) {
    return dataExchangeClient
      .get('/company_information/all')
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.items.map((item) => {
          const dataExchangeCompany = new DataExchangeCompany();
          dataExchangeCompany.init(item, companies, supplierAlertConfigs);

          return dataExchangeCompany;
        });
      })
      .catch((err) => {
        ToastService.httpError(
          ['Anbindungen konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async getAllDataExchanges(companies) {
    return dataExchangeClient
      .get('/all')
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.items.map((item) => {
          const dataExchange = new DataExchange();
          dataExchange.init(item, companies);

          return dataExchange;
        });
      })
      .catch((err) => {
        ToastService.httpError(
          ['Datenaustausch-Einträge konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async updateDataExchangeCompany(body) {
    return dataExchangeClient.put('/company_information', body).catch((err) => {
      ToastService.httpError(
        ['Anbindung konnte nicht upgedated werden.'],
        err.response,
      );
      return Promise.reject(err);
    });
  }

  async updateDataExchangeCompanyRanks(body) {
    return dataExchangeClient
      .put('/company_information/rank', body)
      .catch((err) => {
        ToastService.httpError(
          ['Reihenfolge der Anbindungen konnte nicht upgedated werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async updateDataExchange(body) {
    return dataExchangeClient.put('/', body).catch((err) => {
      ToastService.httpError(
        ['Datenaustausch-Eintrag konnte nicht upgedated werden.'],
        err.response,
      );
      return Promise.reject(err);
    });
  }

  async deleteDataExchange(id) {
    return dataExchangeClient.delete(`/delete/${id}`).catch((err) => {
      ToastService.httpError(
        ['Datenaustausch-Eintrag konnte nicht gelöscht werden.'],
        err.response,
      );
      return Promise.reject(err);
    });
  }

  async loadDataExchangeCompanies() {
    // to not load data exchange companies again when they are already loading or have already been loaded
    if (
      store.getState().dataExchanges?.dataExchangeCompaniesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshDataExchangeCompanies();
  }

  async refreshDataExchangeCompanies() {
    store.dispatch(setDataExchangeCompaniesLoading(LOADING_STATE.LOADING));

    // TODO: Load the companies if they are not already loaded
    const companies = store.getState().companies?.companies;
    const supplierAlertConfigs =
      store.getState().companies?.supplierAlertConfigs;

    const [dataExchangeCompanies, err] = await promiseHandler(
      this.getAllDataExchangeCompanies(companies, supplierAlertConfigs),
    );

    if (err) {
      store.dispatch(setDataExchangeCompaniesLoading(LOADING_STATE.FAILED));
      return;
    }

    store.dispatch(replaceDataExchangeCompanies(dataExchangeCompanies));
  }

  async loadDataExchanges() {
    // to not load data exchanges again when they are already loading or have already been loaded
    if (
      store.getState().dataExchanges?.dataExchangesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshDataExchanges();
  }

  async refreshDataExchanges() {
    store.dispatch(setDataExchangesLoading(LOADING_STATE.LOADING));

    // TODO: Load the companies if they are not already loaded
    const companies = store.getState().companies?.companies;

    const [dataExchanges, err] = await promiseHandler(
      this.getAllDataExchanges(companies),
    );

    if (err) {
      store.dispatch(setDataExchangesLoading(LOADING_STATE.FAILED));
      return;
    }

    store.dispatch(replaceDataExchanges(dataExchanges));
  }
}

export default new DataExchangeService();
