/*
 * Wraps a string in defined markup, e.g. for rendering an array of strings as list or paragraphs.
 * Renders the passed array of paragraphs as plain text by default, but can optionally render as HTML as well.
 *
 * Usage:
 * <ParagraphWrapper paragraphs="This is a <strong>paragraph</strong>" wrapIn="p" asHTML/>
 */

import { CSSProperties } from 'react';

interface P {
  className?: string;
  paragraphs: string | string[];
  style?: CSSProperties;
  wrapIn?: 'div' | 'li' | 'p';
}

export const ParagraphWrapper = ({
  className,
  paragraphs,
  style,
  wrapIn = 'p',
}: P) => {
  const wrapParagraph = (paragraph: string, key: number) => {
    const styleProps = {
      className,
      style,
    };

    switch (wrapIn) {
      case 'div':
        return (
          <div key={key} {...styleProps}>
            {paragraph}
          </div>
        );

      case 'li':
        return (
          <li key={key} {...styleProps}>
            {paragraph}
          </li>
        );

      case 'p':
      default:
        return (
          <p key={key} {...styleProps}>
            {paragraph}
          </p>
        );
    }
  };

  const p = Array.isArray(paragraphs) ? paragraphs : [paragraphs];

  return <>{p.map(wrapParagraph)}</>;
};
