import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '../constants/loadingState';

const companyIdentifiersSlice = createSlice({
  name: 'companyIdentifiers',

  initialState: {
    companyIdentifiers: [],
    companyIdentifiersLoading: LOADING_STATE.NOT_LOADED,
  },

  reducers: (create) => ({
    replaceCompanyIdentifiers: create.reducer((state, param) => {
      const { payload } = param;

      state.companyIdentifiers = [...payload];
      state.companyIdentifiersLoading = LOADING_STATE.SUCCEEDED;
    }),

    setCompanyIdentifiersLoading: create.reducer((state, param) => {
      const { payload } = param;

      state.companyIdentifiersLoading = payload;
    }),
  }),
});

const { actions, reducer } = companyIdentifiersSlice;
export const { replaceCompanyIdentifiers, setCompanyIdentifiersLoading } =
  actions;
export default reducer;
