import { LOADING_STATE } from '../constants/loadingState';
import CompanyIdentifier from '../models/CompanyIdentifier';
import {
  replaceCompanyIdentifiers,
  setCompanyIdentifiersLoading,
} from '../redux/companyIdentifiersSlice';
import store from '../redux/store';
import { promiseHandler } from '../utils/promiseHandler';
import { companyClient } from './apiClient';
import ToastService from './toast.service';

class CompanyIdentifierService {
  async getAllCompanyIdentifiers() {
    return companyClient
      .get('/identifier')
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.map((item) => {
          const companyIdentifier = new CompanyIdentifier();
          companyIdentifier.init(item);

          return companyIdentifier;
        });
      })
      .catch((err) => {
        ToastService.httpError(
          ['Kundennummern konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async createNewCompanyIdentifier(body) {
    companyClient.post('/identifier', body);
  }

  async deleteCompanyIdentifier(companyIdentifierId) {
    return companyClient.delete(`/identifier/${companyIdentifierId}`);
  }

  async loadCompanyIdentifiers() {
    // to not load companyIdentifiers again when they are already loading or have already been loaded
    if (
      store.getState().companyIdentifiers?.companyIdentifiersLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshCompanyIdentifiers();
  }

  async refreshCompanyIdentifiers() {
    store.dispatch(setCompanyIdentifiersLoading(LOADING_STATE.LOADING));

    const [companyIdentifiers, err] = await promiseHandler(
      this.getAllCompanyIdentifiers(),
    );

    if (err) {
      store.dispatch(setCompanyIdentifiersLoading(LOADING_STATE.FAILED));
      return;
    }

    store.dispatch(replaceCompanyIdentifiers(companyIdentifiers));
  }
}

export default new CompanyIdentifierService();
