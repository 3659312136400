import { isNotNullOrUndefined } from '~/utils/lang';

/**
 * Retrieves a deeply nested property from an object.
 *
 * @param {Record<string, unknown>} obj - The object from which to retrieve the property.
 * @param {string} nestedProperty - The path to the nested property, separated by dots.
 * @return {unknown} The value of the nested property.
 */
export const getNestedProperty = (
  obj: Record<string, unknown>,
  nestedProperty: string,
): unknown => {
  const propertyPath = nestedProperty.split('.');

  return propertyPath.reduce((current, key) => current?.[key], obj);
};

/**
 * Returns an array with the IDs that were removed from a ListManager field array.
 *
 * @typeparam T - The type of the objects in the field array.
 * @param previousData - The previous data object.
 * @param currentData - The current data object.
 * @param fieldArrayName - The name of the field array.
 * @returns An array of removed IDs.
 */
export const getRemovedListManagerItems = <
  T extends { id: number | string | null },
>(
  previousData: Record<string, T[]>,
  currentData: Record<string, T[]>,
  fieldArrayName: string,
  idPropertyPath = 'id',
): string[] => {
  const previousCDIds =
    previousData[fieldArrayName]
      ?.map((item) => String(getNestedProperty(item, idPropertyPath)))
      .filter(isNotNullOrUndefined) ?? [];

  const currentDataCDIds = new Set(
    currentData[fieldArrayName]
      ?.map((item) => String(getNestedProperty(item, idPropertyPath)))
      .filter(isNotNullOrUndefined) ?? [],
  );

  return previousCDIds.filter((id) => id != null && !currentDataCDIds.has(id));
};

/**
 * Flattens an array of objects and extracts an array of IDs from a nested property.
 * @param data - The array of objects to flatten.
 * @param nestedProperty - The name of the nested property containing the IDs, can include multiple levels separated by '.'.
 * @returns An array of IDs extracted from the nested property.
 */
export const flatMapIDs = (
  data: Record<string, unknown>[],
  nestedProperty: string,
): number[] => {
  return data
    .flatMap((d) =>
      getNestedProperty(d, nestedProperty)?.map(
        ({ id }: { id: number | null }) => id,
      ),
    )
    .filter(isNotNullOrUndefined);
};
