import {
  IconButton,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import { ReactNode } from 'react';

import { Tooltip } from '~/ui/molecules/Tooltip';

import { cn } from '~/utils';

export type ButtonProps = MuiButtonProps & {
  buttonStyle?: 'primary' | 'secondary' | 'link';
  icon?: ReactNode;
  inProgress?: boolean;
  text?: string;
  title?: string;
} & ComponentStyling;

// Temporary interface helper function to ease migration from Material UI
const buttonStyleToVariant = (
  buttonStyle: ButtonProps['buttonStyle'],
): ButtonProps['variant'] => {
  switch (buttonStyle) {
    case 'primary':
      return 'contained';

    case 'secondary':
      return 'outlined';

    case 'link':
      return 'text';

    default:
      return 'contained';
  }
};

export const Button = ({
  buttonStyle = 'primary',
  children,
  className,
  color = 'primary',
  disabled = false,
  icon,
  inProgress,
  onClick,
  style,
  text,
  title,
  variant,
  ...rest
}: ButtonProps) => {
  const buttonProps = {
    className: cn('!cursor-pointer whitespace-nowrap', className),
    color,
    disabled: disabled || inProgress,
    onClick,
    style,
    variant: variant ?? buttonStyleToVariant(buttonStyle),
    ...rest,
  };

  const ButtonComponent =
    icon && !text ? (
      <IconButton {...buttonProps}>{icon}</IconButton>
    ) : (
      <MuiButton {...buttonProps} startIcon={icon}>
        {text}
        {children}
      </MuiButton>
    );

  return title ? (
    <Tooltip content={title}>{ButtonComponent}</Tooltip>
  ) : (
    ButtonComponent
  );
};
