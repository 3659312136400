import { LOADING_STATE } from '../constants/loadingState';
import User from '../models/User';
import store from '../redux/store';
import { replaceUsers, setUsersLoading } from '../redux/usersSlice';
import { promiseHandler } from '../utils/promiseHandler';
import { userClient, vestigasAdminClient } from './apiClient';
import ToastService from './toast.service';

class UserServiceClass {
  async getAllUsers() {
    return userClient
      .get('/all')
      .then(({ data, status }) => {
        if (status !== 200) {
          return [];
        }

        return data.users.map((item) => {
          const user = new User();
          user.init(item);

          return user;
        });
      })
      .catch((err) => {
        ToastService.httpError(
          ['Benutzer konnten nicht geladen werden.'],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async createNewUser(body) {
    return vestigasAdminClient
      .post('/users', body)
      .then(({ data }) => {
        ToastService.info(`Benutzer ${body?.email} wurde angelegt.`);

        return data?.id;
      })
      .catch((err) => {
        ToastService.httpError(
          [`Benutzer ${body?.email} konnte nicht angelegt werden.`],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async updateUser(userId, body) {
    return vestigasAdminClient
      .put(`/users/${userId}`, body)
      .then(({ data }) => {
        ToastService.info(`Benutzer ${body?.email} wurde aktualisiert.`);

        return data?.id;
      })
      .catch((err) => {
        ToastService.httpError(
          [`Benutzer ${body?.email} konnte nicht aktualisiert werden.`],
          err.response,
        );
        return Promise.reject(err);
      });
  }

  async loadUsers() {
    // to not load users again when they are already loading or have already been loaded
    if (store.getState().users?.usersLoading !== LOADING_STATE.NOT_LOADED) {
      return;
    }

    this.refreshUsers();
  }

  async refreshUsers() {
    store.dispatch(setUsersLoading(LOADING_STATE.LOADING));

    const [users, err] = await promiseHandler(this.getAllUsers());

    if (err) {
      store.dispatch(setUsersLoading(LOADING_STATE.FAILED));
      return;
    }

    store.dispatch(replaceUsers(users));
  }
}

export const UserService = new UserServiceClass();
