import { IconButton } from '@mui/material';
import ms from 'ms';
import toast from 'react-hot-toast';

import { Icon } from '~/ui/atoms';

class ToastService {
  constructor() {
    this.MESSAGE = {
      UNAUTHORIZED_ERROR:
        'Daten konnten nicht synchronisiert werden, da Sie keine gültige Session haben.',
      UNAUTHORIZED_ERROR_REDIRECT: 'Bitte loggen Sie sich aus und wieder ein.',
      COMPANY_ACCOUNT_LOAD_FAILED:
        'Firmen Accounts konnten nicht geladen werden.',
      COMPANY_ACCOUNT_UPDATE_FAILED:
        'Firmen Account konnte nicht upgedated werden.',
    };

    this.showUnauthorizedError = true;

    this.alreadyShownDataLoadErrors = [];
    this.alreadyShownUpdatedDlns = [];

    this.unauthorizedErrorTimer = null;
    this.dataLoadErrorTimers = [];
  }

  displayToast(lines, icon, duration, backgroundColor) {
    const message = Array.isArray(lines) ? lines : [lines];

    toast(
      (t) => (
        <span
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <div>
            {}
            {message.map((line, i) => (
              <div key={i}>{line}</div>
            ))}
          </div>
          <IconButton
            onClick={() => toast.dismiss(t.id)}
            style={{ marginLeft: '10px' }}
          >
            <Icon metaphor="close" className="text-white" />
          </IconButton>
        </span>
      ),
      {
        icon,
        duration,
        style: {
          background: backgroundColor,
          color: '#fff',
        },
      },
    );
  }

  getStyledLink(message, link) {
    return null;
    // return (
    //   <Link to={link} style={{ textDecoration: 'none', color: '#fff', fontWeight: 'font-bold' }}>
    //     {message}
    //   </Link>
    // );
  }

  info(lines) {
    this.displayToast(
      lines,
      <Icon metaphor="info" className="ml-2 mr-1" />,
      ms('2s'),
      '#216cfd',
    );
  }

  warning(lines) {
    this.displayToast(
      lines,
      <Icon metaphor="warningRounded" className="ml-2 mr-1" />,
      ms('6s'),
      '#fd9401',
    );
  }

  error(lines) {
    this.displayToast(
      lines,
      <Icon metaphor="cancelRounded" className="ml-2 mr-1" />,
      ms('6s'),
      '#d32f2f',
    );
  }

  httpError(lines, errorResponse) {
    switch (errorResponse?.status) {
      case 401:
        // workaround for bug/VGS-1500: don't show error toast if loading of data has run into 401
        if (this.isLoadMessage(lines[0])) {
          break;
        }

        if (this.showUnauthorizedError) {
          this.error([
            this.MESSAGE.UNAUTHORIZED_ERROR,
            this.MESSAGE.UNAUTHORIZED_ERROR_REDIRECT,
          ]);
          this.resetUnauthorizedErrorTimer();
        }
        break;

      case 400:
      case 404:
      case 405:
      case 500:
      case 502:
      case 503:
        // prevent that user is spammed with data load errors
        if (this.isLoadMessage(lines[0])) {
          if (!this.alreadyShownDataLoadErrors.includes(lines[0])) {
            this.error([...lines]);
          }
        } else {
          this.error([...lines]);
        }
        break;

      case 422:
        if (Array.isArray(errorResponse?.data?.detail)) {
          errorResponse?.data?.detail?.forEach((validationError) => {
            lines.push(this.getValidationError(validationError));
          });
        } else if (errorResponse?.data?.detail) {
          lines.push(errorResponse?.data?.detail);
        }
        this.error(lines);
        break;

      default:
        this.error(lines);
        break;
    }
  }

  getValidationError(validationError) {
    switch (validationError.type) {
      case 'value_error.email':
        return 'E-Mail Adresse ungültig.';

      case 'value_error':
        if (validationError.loc?.includes('latitude')) {
          return 'Breitengrad ungültig.';
        }
        if (validationError.loc?.includes('longitude')) {
          return 'Längengrad ungültig.';
        }
        break;

      default:
        return 'Ungültige Eingabe eines Feldes.';
    }
  }

  resetUnauthorizedErrorTimer() {
    this.showUnauthorizedError = false;

    this.unauthorizedErrorTimer = setTimeout(
      function () {
        this.showUnauthorizedError = true;
      }.bind(this),
      ms('3s'),
    );
  }

  isLoadMessage(inputMessage) {
    const constMessage = Object.keys(this.MESSAGE).find(
      (x) => this.MESSAGE[x] === inputMessage,
    );

    return constMessage?.includes('LOAD');
  }
}

export default new ToastService();
