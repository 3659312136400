import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import applyCaseMiddleware from 'axios-case-converter';
import urlcat from 'urlcat';

import { Config } from '../Config';
import { API_PATH } from '../constants';
import AuthService from './auth.service';

const accessToken = localStorage.getItem('accessToken');

// Automatically refresh tokens when a request returns a 401 Unauthorized error.
createAuthRefreshInterceptor(axios, AuthService.refreshTokens);

/**
 * Get the API base URL based on the environment.
 *
 * @param {string} api - The API endpoint
 * @param {string} env - The environment (e.g. 'staging')
 * @return {string} The base URL for the given environment and API
 */
const getBaseURL = (api, env) => {
  if (env === 'staging') {
    return urlcat(Config.stagingApiUrl, api);
  }

  return urlcat(Config.apiUrl, api);
};

const commonConfig = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
  withCredentials: true,
};

const trailingSlashRegex = /\/+$/;

/**
 * Creates an Axios instance for the given API.
 * Allows to specify the API, a specific environment, and whether to apply a case converter,
 * which automatically translates back and forth between the case used by the frontend (camelCase)
 * and the case used by the API (snake_case).
 *
 * @param {string} apiPath - The API path.
 * @param {string} [env='current'] - The environment.
 * @param {boolean} [withCaseConverter=false] - Whether to apply a case converter.
 * @return {AxiosInstance} - The created Axios instance.
 */
const createAxiosInstance = (
  apiPath,
  env = 'current',
  withCaseConverter = false,
) => {
  const baseURL = getBaseURL(apiPath, env);

  const instance = withCaseConverter
    ? applyCaseMiddleware(
        axios.create({
          ...commonConfig,
          baseURL,
        }),
      )
    : axios.create({
        ...commonConfig,
        baseURL,
      });

  // Add a request interceptor to remove trailing slashes, because our API breaks at requests with trailing slashes.
  instance.interceptors.request.use((config) => {
    if (config.url) {
      config.url = config.url.replace(trailingSlashRegex, '');
    }

    return config;
  });

  // // Add a request interceptor to remove undefined properties and empty objects from the posted data.
  // instance.interceptors.request.use(
  //   (config) => {
  //     if (config.data) {
  //       config.data = removeUndefinedKeys(config.data);
  //     }

  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   },
  // );

  // Add a response interceptor to handle errors.
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Log the error.
      console.error('Axios error:', error);

      return Promise.reject(error); // Propagate the error to handle it in the calling function.
    },
  );

  return instance;
};

export const accessControlClient = createAxiosInstance(API_PATH.ACCESS_CONTROL);
export const companyAccountClient = createAxiosInstance(
  API_PATH.COMPANY_ACCOUNT,
);
export const companyAddressClient = createAxiosInstance(
  API_PATH.COMPANY_ADDRESS,
);
export const companyAddressClientWCaseConverter = createAxiosInstance(
  API_PATH.COMPANY_ADDRESS,
  'current',
  true,
);
export const companyClient = createAxiosInstance(API_PATH.COMPANY);
export const companyClientWCaseConverter = createAxiosInstance(
  API_PATH.COMPANY,
  'current',
  true,
);
export const companyClientStaging = createAxiosInstance(
  API_PATH.COMPANY,
  'staging',
);
export const dataExchangeClient = createAxiosInstance(API_PATH.DATA_EXCHANGE);
export const dataExchangeClientWCaseConverter = createAxiosInstance(
  API_PATH.DATA_EXCHANGE,
  'current',
  true,
);
export const roleClient = createAxiosInstance(API_PATH.ROLE);
export const rootClient = createAxiosInstance(API_PATH.ROOT);
export const rootClientStaging = createAxiosInstance(API_PATH.ROOT, 'staging');
export const userClient = createAxiosInstance(API_PATH.USERS);
export const userClientWCaseConverter = createAxiosInstance(
  API_PATH.USERS,
  'current',
  true,
);
export const vestigasAdminClient = createAxiosInstance(API_PATH.VADMIN);
export const vestigasAdminClientWCaseConverter = createAxiosInstance(
  API_PATH.VADMIN,
  'current',
  true,
);
export const vestigasAdminClientStaging = createAxiosInstance(
  API_PATH.VADMIN,
  'staging',
);
