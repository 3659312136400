import { i18n } from '~/utils';

// TODO: This needs to be a full-blown error message utility

const errorType = {
  valueError: {
    missing: i18n.t('deliveryNote.errors.validation.type.valueError.missing'),
  },
};

const errorMessage = {
  'field required': i18n.t(
    'deliveryNote.errors.validation.message.fieldRequired',
  ),
};

export const mapValidationError = ({ loc, msg, type }) => ({
  loc,
  path: loc.join('.'),
  type: errorType.valueError?.[type.split('.')?.[1]] ?? type,
  msg: errorMessage[msg ?? msg],
});

export const parseValidationErrors = (dataValidationErrors) => {
  if (!dataValidationErrors) {
    return [];
  }

  return dataValidationErrors.map(mapValidationError);
};
