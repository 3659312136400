export default class DataExchangeCompany {
  constructor() {
    this.id = '';
    this.name = '';
    this.isSender = false;
    this.isReceiver = false;
    this.statusDeliveryNote = DataExchangeCompany.STATUS.NO_STATUS.KEY;
    this.statusInvoice = DataExchangeCompany.STATUS.NO_STATUS.KEY;
    this.internalStatusDeliveryNote = DataExchangeCompany.STATUS.NO_STATUS.KEY;
    this.internalStatusInvoice = DataExchangeCompany.STATUS.NO_STATUS.KEY;
    this.comment = '';
    this.internalComment = '';
    this.knownIssues = '';
    this.furtherInformation = '';
    this.companyAccountId = '';
    this.rank = 0;
    this.blacklisted = false;
    this.currentIssues = '';
  }

  init(dataExchangeCompany, companies, supplierAlertConfigs) {
    this.id = dataExchangeCompany?.company_id ?? '';
    this.name =
      companies.find(
        (company) => company.id === dataExchangeCompany?.company_id,
      )?.name ?? '';
    this.isSender = dataExchangeCompany?.is_sender ?? '';
    this.isReceiver = dataExchangeCompany?.is_receiver ?? '';
    this.statusDeliveryNote = dataExchangeCompany?.status_dln ?? '';
    this.statusInvoice = dataExchangeCompany?.status_invoice ?? '';
    this.internalStatusDeliveryNote =
      dataExchangeCompany?.internal_status_dln ?? '';
    this.internalStatusInvoice =
      dataExchangeCompany?.internal_status_invoice ?? '';
    this.comment = dataExchangeCompany?.status_comment ?? '';
    this.internalComment = dataExchangeCompany?.internal_status_comment ?? '';
    this.knownIssues = dataExchangeCompany?.known_issues ?? '';
    this.furtherInformation = dataExchangeCompany?.further_information ?? '';
    this.companyAccountId = dataExchangeCompany?.company_account_id ?? '';
    this.rank = dataExchangeCompany?.rank ?? 0;
    this.blacklisted = dataExchangeCompany?.blacklisted ?? false;
    this.currentIssues = dataExchangeCompany?.current_issues ?? '';
    this.supplierAlertConfig =
      supplierAlertConfigs.find((config) => config.companyId === this.id) ??
      null;
  }

  clone(dataExchangeCompany) {
    this.id = dataExchangeCompany?.id;
    this.name = dataExchangeCompany?.name;
    this.isSender = dataExchangeCompany?.isSender;
    this.isReceiver = dataExchangeCompany?.isReceiver;
    this.statusDeliveryNote = dataExchangeCompany?.statusDeliveryNote;
    this.statusInvoice = dataExchangeCompany?.statusInvoice;
    this.internalStatusDeliveryNote =
      dataExchangeCompany?.internalStatusDeliveryNote;
    this.internalStatusInvoice = dataExchangeCompany?.internalStatusInvoice;
    this.comment = dataExchangeCompany?.comment;
    this.internalComment = dataExchangeCompany?.internalComment;
    this.knownIssues = dataExchangeCompany?.knownIssues;
    this.furtherInformation = dataExchangeCompany?.furtherInformation;
    this.companyAccountId = dataExchangeCompany?.companyAccountId;
    this.rank = dataExchangeCompany?.rank;
    this.blacklisted = dataExchangeCompany?.blacklisted;
    this.currentIssues = dataExchangeCompany?.currentIssues;
    this.supplierAlertConfig = dataExchangeCompany?.supplierAlertConfig;
  }

  static getStatusLabel(statusKey) {
    const status = Object.keys(DataExchangeCompany.STATUS).find(
      (x) => DataExchangeCompany.STATUS[x].KEY === statusKey,
    );

    if (!status) return null;

    return DataExchangeCompany.STATUS[status].LABEL;
  }

  static getStatusRank(statusKey) {
    const status = Object.keys(DataExchangeCompany.STATUS).find(
      (x) => DataExchangeCompany.STATUS[x].KEY === statusKey,
    );

    if (!status) return DataExchangeCompany.STATUS.NO_STATUS.RANK;

    return DataExchangeCompany.STATUS[status].RANK;
  }

  // get the correct CSS class for the corresponding status
  static switchClassName(statusKey) {
    const status = Object.keys(DataExchangeCompany.STATUS).find(
      (x) => DataExchangeCompany.STATUS[x].KEY === statusKey,
    );

    if (!status) return DataExchangeCompany.STATUS.NO_STATUS.CLASS;

    return DataExchangeCompany.STATUS[status].CLASS;
  }

  static STATUS = {
    LIVE: {
      KEY: 'live',
      LABEL: 'Live',
      DESCRIPTION: 'Produktive Anbindung',
      RANK: 1,
      CLASS: 'data-exchange-status-live',
    },
    QR_CODE: {
      KEY: 'qr_code',
      LABEL: 'QR-Code Anbindung',
      DESCRIPTION: '',
      RANK: 2,
      CLASS: 'data-exchange-status-qr-code',
    },
    TEST_PHASE: {
      KEY: 'test_phase',
      LABEL: 'Testphase',
      DESCRIPTION:
        'Die Schnittstelle wurde hergestellt, befindet sich aber noch in einer Testphase (bevor ein Dauerbetrieb möglich ist)',
      RANK: 3,
      CLASS: 'data-exchange-status-test-phase',
    },
    IMPLEMENTATION_SUPPLIER: {
      KEY: 'implementation_supplier',
      LABEL: 'Implementierung Lieferant',
      DESCRIPTION:
        'Ggf. notwendige Anpassungen an der Schnittstelle werden vom Lieferanten implementiert',
      RANK: 4,
      CLASS: 'data-exchange-status-implementation-supplier',
    },
    IMPLEMENTATION_VESTIGAS: {
      KEY: 'implementation_vestigas',
      LABEL: 'Implementierung VESTIGAS',
      DESCRIPTION:
        'Ein notwendiger Datenparser wird von VESTIGAS implementiert',
      RANK: 5,
      CLASS: 'data-exchange-status-implementation-vestigas',
    },
    TEST_DATA_EXCHANGED: {
      KEY: 'test_data_exchanged',
      LABEL: 'Testdaten wurden ausgetauscht',
      DESCRIPTION:
        'Erste Testdaten wurden zwischen VESTIGAS und dem Lieferanten ausgetauscht',
      RANK: 6,
      CLASS: 'data-exchange-status-test-data-exchanged',
    },
    CONFIRMED: {
      KEY: 'confirmed',
      LABEL: 'Zusage erteilt',
      DESCRIPTION:
        'Der Lieferant will mit der Umsetzung beginnen und VESTIGAS wartet auf erste Testdatensätze',
      RANK: 7,
      CLASS: 'data-exchange-status-confirmed',
    },
    ON_HOLD_SUPPLIER: {
      KEY: 'on_hold_supplier',
      LABEL: 'Interne Absprachen beim Lieferant',
      DESCRIPTION:
        'Lieferant benötigt Zeit, technische Möglichkeiten intern abzuklären oder die benötigten Ressourcen bereitzustellen',
      RANK: 8,
      CLASS: 'data-exchange-status-on-hold-supplier',
    },
    IN_QUALIFICATION: {
      KEY: 'in_qualification',
      LABEL: 'Gespräch mit Lieferant',
      DESCRIPTION:
        'Der Lieferant hat sich zurückgemeldet und fixiert ein Gespräch mit VESTIGAS',
      RANK: 9,
      CLASS: 'data-exchange-status-in-qualification',
    },
    INTERESTED: {
      KEY: 'interested',
      LABEL: 'Interesse bekundet',
      DESCRIPTION:
        'Es wurde Interesse bekundet und der Abnehmer meldet dieses Interesse beim Lieferanten',
      RANK: 10,
      CLASS: 'data-exchange-status-interested',
    },
    NO_STATUS: {
      KEY: 'no_status',
      LABEL: 'Kein Status',
      DESCRIPTION: 'Kein Status vergeben',
      RANK: 11,
      CLASS: 'data-exchange-status-no-status',
    },
  };
}
