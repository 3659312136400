import cloneDeep from 'lodash/cloneDeep';

export default class DataExchange {
  constructor() {
    this.id = '';
    this.senderId = '';
    this.senderName = '';
    this.receiverId = '';
    this.receiverName = '';
    this.isExchangeViaPaper = false;
    this.exchangeDeliveryNotes = false;
    this.exchangeInvoices = false;
    this.exchangeOrders = false;
    this.activeCustomerIds = [];
    this.knownIssues = '';
    this.furtherInformation = '';
  }

  init(dataExchange, companies) {
    this.id = dataExchange?.id ?? '';
    this.senderId = dataExchange?.sender_id ?? '';
    this.senderName =
      companies.find((company) => company.id === dataExchange?.sender_id)
        ?.name ?? '';
    this.receiverId = dataExchange?.receiver_id ?? '';
    this.receiverName =
      companies.find((company) => company.id === dataExchange?.receiver_id)
        ?.name ?? '';
    this.isExchangeViaPaper = dataExchange?.is_exchange_via_paper ?? false;
    this.exchangeDeliveryNotes = dataExchange?.exchange_lfs ?? false;
    this.exchangeInvoices = dataExchange?.exchange_invoices ?? false;
    this.exchangeOrders = dataExchange?.exchange_orders ?? false;
    this.activeCustomerIds = dataExchange?.active_customer_ids ?? '';
    this.knownIssues = dataExchange?.known_issues ?? '';
    this.furtherInformation = dataExchange?.further_information ?? '';
  }

  clone(dataExchange) {
    // TODO: Doesn't this create a copy by reference, which can lead to the most disgusting bugs? Use proper cloning.
    this.id = dataExchange?.id;
    this.senderId = dataExchange?.senderId;
    this.senderName = dataExchange?.senderName;
    this.receiverId = dataExchange?.receiverId;
    this.receiverName = dataExchange?.receiverName;
    this.isExchangeViaPaper = dataExchange?.isExchangeViaPaper;
    this.exchangeDeliveryNotes = dataExchange?.exchangeDeliveryNotes;
    this.exchangeInvoices = dataExchange?.exchangeInvoices;
    this.exchangeOrders = dataExchange?.exchangeOrders;
    this.activeCustomerIds = cloneDeep(dataExchange?.activeCustomerIds);
    this.knownIssues = dataExchange?.knownIssues;
    this.furtherInformation = dataExchange?.furtherInformation;
  }

  static isValid(dataExchange) {
    if (!dataExchange) return false;
    if (!dataExchange.senderId) return false;
    if (!dataExchange.receiverId) return false;

    // This validation logic is implemented in the backend.
    // However, from the business point of view, it doesn't make sense. For example, we want to track if delivery notes are exchange via paper way to know about the supplier network of our customers and leads.
    // Therefore, keep in mind that the backend logic needs to be adjusted in the future.
    if (dataExchange.isExchangeViaPaper)
      return (
        !dataExchange.exchangeDeliveryNotes &&
        !dataExchange.exchangeInvoices &&
        !dataExchange.exchangeOrders &&
        dataExchange.activeCustomerIds.length === 0
      );

    // If digital data is exchanged, at least one of the three document types must be exchanged.
    if (!dataExchange.isExchangeViaPaper)
      return (
        dataExchange.exchangeDeliveryNotes ||
        dataExchange.exchangeInvoices ||
        dataExchange.exchangeOrders
      );
  }
}
