import {
  addBusinessDays as addBusinessDaysLib,
  addDays as addDaysLib,
  add as addLib,
  addMinutes as addMinutesLib,
  addMonths as addMonthsLib,
  addWeeks as addWeeksLib,
  compareAsc,
  compareDesc,
  differenceInBusinessDays as differenceInBusinessDaysLib,
  differenceInCalendarDays,
  format,
  getDay as getDayLib,
  isAfter,
  isBefore as isBeforeLib,
  isEqual,
  isToday as isTodayLib,
  isValid,
  isWeekend as isWeekendLib,
  min,
  parse,
  parseISO,
  startOfDay,
  toDate,
} from 'date-fns';
import { de, enUS } from 'date-fns/locale';

import { currentLocale } from './i18n';
import { AllowedNames } from './lang';

const LOCALE = {
  de,
  en: enUS,
};

export type DateLike = Date | string | number;

type DateAccessor<T> = (item: T) => DateLike;

/**
 * Returns the current date.
 *
 * @return {Date} The current date.
 */
export const now = (): Date => new Date();

/**
 ** Parses a date-like value into a Date object.
 * Uses the provided format if specified.
 *
 * @param {DateLike} dateLike - The date-like value to parse.
 * @param {string} [format] - The format string to use for parsing the date.
 * @return {Date} The parsed Date object.
 */
export const parseDate = (dateLike: DateLike, format?: string): Date => {
  if (dateLike instanceof Date) {
    return dateLike;
  }

  if (typeof dateLike === 'string' && format) {
    return parse(dateLike, format, new Date());
  }

  const result = toDate(parseISO(String(dateLike)));

  return result;
};

export const formatDate = (date: DateLike, dateFormat: string): string =>
  date
    ? format(parseDate(date), dateFormat, {
        locale: LOCALE[currentLocale],
      })
    : '';

/**
 * Gets the current timezone offset in minutes.
 */
const timezoneOffset = new Date().getTimezoneOffset();

/**
 * Converts a given date from the user's local time to its equivalent UTC date.
 *
 * Offsets the user's local time to UTC time.
 * Important when sending dates to the server, as the server expects UTC time!
 *
 * @param {DateLike} date - The date to convert.
 * @returns {Date | null} - The equivalent UTC date, or null if the input date is undefined or null.
 * @throws {TypeError} - If the input date is not a valid DateLike value.
 */
export const toUTC = (date?: DateLike): Date | null => {
  if (!date) {
    return null;
  }

  const parsedDate = parseDate(date);

  if (isNaN(parsedDate.getTime())) {
    return null;
  }

  const UTCDate = add(parsedDate, { minutes: timezoneOffset });

  /**
   * If the provided date is at 00:00, the resulting UTC date should also be returned as 00:00.
   * This handles the case that the date is set without time locally.
   * Our local-time-to-UTC conversion would then add the timezone offset
   * thus shifting the time (and date!) sent to the server to something other than 00:00 of that date.
   */
  const dateAsDate = toDate(parsedDate);
  if (isEqual(dateAsDate, startOfDay(dateAsDate))) {
    return parsedDate;
  }

  return UTCDate;
};

/**
 * Converts a given date from UTC to the user's local time.
 *
 * Offsets UTC time to the user's local time.
 * Important when reading dates from the server, as the server sends UTC time!
 *
 * @param {DateLike} date - The date to convert from UTC.
 * @returns {Date | null} - The equivalent date in the user's local time, or null if the input date is undefined or null.
 */
export const fromUTC = (date?: DateLike): Date | null => {
  if (!date) {
    return null;
  }

  const parsedDate = parseDate(date);
  if (isNaN(parsedDate.getTime())) {
    return null;
  }

  const localDate = add(parsedDate, { minutes: -1 * timezoneOffset });

  /**
   * If date is a string without time information, set the time to midnight.
   * This handles the case that a simple date is sent by the server,
   * which is meant to represent start of day of that date,
   * but our UTC-to-local-time conversion would interpret the input as start of day
   * and add the timezone offset.
   */
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return startOfDay(localDate);
  }

  return localDate;
};

/**
 * Formats a given date using the specified date format after converting it from local time to a UTC date.
 *
 * @param {DateLike} date - The date to format.
 * @param {string} dateFormat - The format to use for the date.
 * @return {string} The formatted date as a UTC string.
 */
export const formatAsUTCDate = (date: DateLike, dateFormat: string): string => {
  const UTCDate = toUTC(date);

  if (!UTCDate) {
    return '';
  }

  return format(UTCDate, dateFormat);
};

/**
 * Formats a given date using the specified date format after parsing it from a UTC date into a local date.
 *
 * @param {DateLike} date - The date to format from UTC.
 * @param {string} dateFormat - The format to use for the date.
 * @return {string} The formatted date as a local time string.
 */
export const formatFromUTCDate = (date: DateLike, dateFormat: string): string =>
  format(fromUTC(date) ?? '', dateFormat);

export const getDay = getDayLib;

export const add = addLib;
export const addMinutes = (date: DateLike, minutes: number): Date =>
  addMinutesLib(parseDate(date), minutes);
export const addDays = (date: DateLike, days: number): Date =>
  addDaysLib(parseDate(date), days);
export const addBusinessDays = (date: DateLike, days: number): Date =>
  addBusinessDaysLib(parseDate(date), days);
export const addWeeks = (date: DateLike, weeks: number): Date =>
  addWeeksLib(parseDate(date), weeks);
export const addMonths = (date: DateLike, months: number): Date =>
  addMonthsLib(parseDate(date), months);

export const differenceInDays = (date1: DateLike, date2: DateLike): number =>
  differenceInCalendarDays(parseDate(date1), parseDate(date2));

export const differenceInBusinessDays = (
  date1: DateLike,
  date2: DateLike,
): number => differenceInBusinessDaysLib(parseDate(date1), parseDate(date2));

export const isBefore = (earlierDate: DateLike, laterDate: DateLike) =>
  isBeforeLib(parseDate(earlierDate), parseDate(laterDate));

export const isAfterOrEqual = (laterDate: DateLike, earlierDate: DateLike) => {
  const d1 = parseDate(laterDate);
  const d2 = parseDate(earlierDate);

  return isAfter(d1, d2) || isEqual(d1, d2);
};

export const minDate = (dates: DateLike[]): Date => {
  if (!Array.isArray(dates)) {
    return null;
  }

  const d = dates.map((it) => parseDate(it)).filter(isValid);

  if (!d.length) {
    return null;
  }

  return min(d);
};

export const isFuture = (date: DateLike): boolean =>
  isAfter(parseDate(date), new Date());

export const isPast = (date: DateLike): boolean =>
  isBeforeLib(parseDate(date), new Date());

export const isToday = (date: DateLike): boolean => isTodayLib(parseDate(date));

export const isWeekend = (date: Date) => isWeekendLib(date);

export const compareDatesDesc = (a: DateLike, b: DateLike) =>
  compareDesc(parseDate(a), parseDate(b));

/**
 * Sorts items in descending order based on the provided date property.
 *
 * @param {T[]} items - An array of items to be sorted.
 * @param {AllowedNames<T, DateLike> | DateAccessor<T>} dateProperty - The date property used for sorting.
 * @return {T[]} - The sorted array of items.
 */
export const newestFirst = <T extends Record<string, object>>(
  items: T[],
  dateProperty: AllowedNames<T, DateLike> | DateAccessor<T>,
) => {
  return typeof dateProperty === 'function'
    ? items.sort((a, b) =>
        compareDesc(parseDate(dateProperty(a)), parseDate(dateProperty(b))),
      )
    : items.sort((a, b) =>
        // @ts-expect-error // figure out how to type this properly
        compareDesc(parseDate(a[dateProperty]), parseDate(b[dateProperty])),
      );
};

/**
 * Sorts an array of objects in ascending order based on a specified date property.
 *
 * @template T - The type of objects in the array.
 * @param {T[]} items - The array of objects to be sorted.
 * @param {AllowedNames<T, DateLike> | DateAccessor<T>} dateProperty - The property or function used to extract the date from each object.
 * @return {T[]} - The sorted array of objects.
 */
export const oldestFirst = <T extends Record<string, object>>(
  items: T[],
  dateProperty: AllowedNames<T, DateLike> | DateAccessor<T>,
) => {
  return typeof dateProperty === 'function'
    ? items.sort((a, b) =>
        compareAsc(parseDate(dateProperty(a)), parseDate(dateProperty(b))),
      )
    : items.sort((a, b) =>
        // @ts-expect-error // figure out how to type this properly
        compareAsc(parseDate(a[dateProperty]), parseDate(b[dateProperty])),
      );
};
