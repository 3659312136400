import { QueryClient } from '@tanstack/react-query';
import { Navigate, Outlet } from 'react-router-dom';

import { Icon } from '~/ui/atoms';

import { assetReparsingLoader } from '~/data/asset-reparsing';
import { i18n } from '~/utils';

import { ErrorPage } from './components';
import { RedirectRouteType, RouteType } from './types';

const queryClient = new QueryClient();

/**
 * Returns an object representing a redirect route to the specified destination.
 * Handles nested routes where the parent route has no corresponding component to render.
 * E.g. for a route like '/clients', a redirect handler can be added to redirect '/clients' to '/clients/company-accounts'.
 *
 * @param {string} to - The destination URL to redirect to.
 * @return {object} A config object for a React Router BrowserRouter config.
 *   - `path` (string): The matched path (an empty string).
 *   - `element` (ReactElement): React Router's `Navigate` component, which redirects to the given URL.
 *   - `omitFromNav` (boolean): Indicates whether the redirect route should be omitted from the navigation menu.
 */
export const redirectRootRouteTo = (to: string) => ({
  path: '',
  element: <Navigate to={to} replace />,
  omitFromNav: true,
});

/**
 * Link names object for all routes in the application to be used in internal links.
 * Resolves a link name to its corresponding path.
 *
 * @example: linkTo.clients.users => '/clients/users'
 */
export const linkTo = {
  home: '/home',
  tasks: {
    aiParserReview: '/ai-parser-review',
    assetReparsing: '/asset-reparsing',
  },
  clients: {
    companyAccounts: '/clients/company-accounts',
    companies: '/clients/companies',
    users: '/clients/users',
  },
  connections: {
    dataExchangeCompanies: '/connections/data-exchange-companies',
    dataExchanges: '/connections/data-exchanges',
  },
  endpoints: '/endpoints',
} as const;

export const ROUTES: (RouteType | RedirectRouteType)[] = [
  redirectRootRouteTo('/home'), // TODO: inject this in buildNavConfig
  {
    name: 'home',
    path: linkTo.home,
    label: i18n.t('routes.home.label'),
    icon: <Icon metaphor="home" />,
    lazy: () =>
      import('../home').then(({ Home }) => ({
        Component: Home,
      })),
    errorElement: (
      <ErrorPage
        title={i18n.t('routes.home.error.title')}
        message={i18n.t('routes.home.error.message')}
      />
    ),
  },
  {
    name: 'aiParserReview',
    path: linkTo.tasks.aiParserReview,
    label: i18n.t('routes.aiParserReview.label'),
    icon: <Icon metaphor="processWithAI" />,
    lazy: () =>
      import('../ai-parser-review').then(({ AIParserReview }) => ({
        Component: AIParserReview,
        loader: assetReparsingLoader(queryClient),
      })),
    errorElement: (
      <ErrorPage
        title={i18n.t('routes.aiParserReview.error.title')}
        message={i18n.t('routes.aiParserReview.error.message')}
      />
    ),
  },
  {
    name: 'assetReparsing',
    path: linkTo.tasks.assetReparsing,
    label: i18n.t('routes.assetReparsing.label'),
    icon: <Icon metaphor="replay" />,
    lazy: () =>
      import('../asset-reparsing').then(({ AssetReparsing }) => ({
        Component: AssetReparsing,
        loader: assetReparsingLoader(queryClient),
      })),
    errorElement: (
      <ErrorPage
        title={i18n.t('routes.assetReparsing.error.title')}
        message={i18n.t('routes.assetReparsing.error.message')}
      />
    ),
  },
  {
    name: 'clients',
    path: '/clients',
    label: i18n.t('routes.clients.label'),
    icon: <Icon metaphor="star" />,
    element: <Outlet />, // Make sure that the current path is rendered in the first place; redirectRootRouteTo redirects to a specified target.
    errorElement: (
      <ErrorPage
        title={i18n.t('routes.clients.error.title')}
        message={i18n.t('routes.clients.error.message')}
      />
    ),

    children: [
      redirectRootRouteTo('/clients/company-accounts'), // TODO: inject this in buildNavConfig
      {
        name: 'clientsCompanyAccounts',
        path: linkTo.clients.companyAccounts,
        label: i18n.t('routes.clientsCompanyAccounts.label'),
        icon: <Icon metaphor="star" />,
        lazy: () =>
          import('../clients/company-accounts').then(({ CompanyAccounts }) => ({
            Component: CompanyAccounts,
          })),
        errorElement: (
          <ErrorPage
            title={i18n.t('routes.clientsCompanyAccounts.error.title')}
            message={i18n.t('routes.clientsCompanyAccounts.error.message')}
          />
        ),
      },
      {
        name: 'clientsCompanies',
        path: linkTo.clients.companies,
        label: i18n.t('routes.companies.label'),
        icon: <Icon metaphor="company" />,
        lazy: () =>
          import('../clients/companies').then(({ Companies }) => ({
            Component: Companies,
          })),
        errorElement: (
          <ErrorPage
            title={i18n.t('routes.companies.error.title')}
            message={i18n.t('routes.companies.error.message')}
          />
        ),
      },
      {
        name: 'clientsUsers',
        path: linkTo.clients.users,
        label: i18n.t('routes.users.label'),
        icon: <Icon metaphor="person" />,
        lazy: () =>
          import('../clients/users').then(({ Users }) => ({
            Component: Users,
          })),
        errorElement: (
          <ErrorPage
            title={i18n.t('routes.users.error.title')}
            message={i18n.t('routes.users.error.message')}
          />
        ),
      },
    ],
  },
  {
    name: 'supplierConnections',
    path: '/connections',
    label: i18n.t('routes.supplierConnections.label'),
    icon: <Icon metaphor="folderSpecial" />,
    element: <Outlet />, // Make sure that the current path is rendered in the first place; redirectRootRouteTo redirects to a specified target.
    children: [
      redirectRootRouteTo('/connections/data-exchange-companies'), // TODO: inject this in buildNavConfig
      {
        name: 'supplierConnectionsDataExchangeCompanies',
        path: linkTo.connections.dataExchangeCompanies,
        label: i18n.t('routes.supplierConnectionsDataExchangeCompanies.label'),
        icon: <Icon metaphor="folderSpecial" />,
        lazy: () =>
          import('../supplier-connections/data-exchange-companies').then(
            ({ DataExchangeCompanies }) => ({
              Component: DataExchangeCompanies,
            }),
          ),
        errorElement: (
          <ErrorPage
            title={i18n.t('routes.users.error.title')}
            message={i18n.t('routes.users.error.message')}
          />
        ),
      },
      {
        name: 'supplierConnectionsDataExchanges',
        path: linkTo.connections.dataExchanges,
        label: i18n.t('routes.supplierConnectionsDataExchanges.label'),
        icon: <Icon metaphor="dataExchange" />,
        lazy: () =>
          import('../supplier-connections/data-exchanges').then(
            ({ DataExchanges }) => ({
              Component: DataExchanges,
            }),
          ),
        errorElement: (
          <ErrorPage
            title={i18n.t('routes.supplierConnections.error.title')}
            message={i18n.t('routes.supplierConnections.error.message')}
          />
        ),
      },
    ],
    errorElement: (
      <ErrorPage
        title={i18n.t('routes.supplierConnectionsDataExchanges.error.title')}
        message={i18n.t(
          'routes.supplierConnectionsDataExchanges.error.message',
        )}
      />
    ),
  },
  {
    name: 'endpoints',
    path: linkTo.endpoints,
    label: i18n.t('routes.endpoints.label'),
    icon: <Icon metaphor="endpoint" />,
    lazy: () =>
      import('../endpoints').then(({ Endpoints }) => ({
        Component: Endpoints,
      })),
    errorElement: (
      <ErrorPage
        title={i18n.t('routes.endpoints.error.title')}
        message={i18n.t('routes.endpoints.error.message')}
      />
    ),
  },
];
