'use client';

import { create } from 'mutative';
import {
  CSSProperties,
  Children,
  Fragment,
  ReactElement,
  UIEvent,
  cloneElement,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as ReactDOM from 'react-dom';

import {
  ArrowParams,
  Point,
  TooltipAlign,
  TooltipPlacement,
  calcTooltipPosition,
  getArrowParams,
  improveTooltipPlacement,
  placementToArrowParams,
} from './tooltipPlacement';
import { DetectType, Trigger } from './Trigger';

type TooltipControllerProps = {
  animation?: string;
  arrowColor?: string;
  autoPlacement?: boolean;
  children: ReactElement[];
  closeOnClick?: boolean;
  detect: DetectType;
  duration?: string;
  id?: string;
  placement?: TooltipPlacement;
  align?: TooltipAlign;
  properties?: string[];
  returnState?: (isOpen: boolean) => any;
  targetMargin?: Point; // Margin applied to target area calculation, allows to adjust tooltip offset relative to target
  timeOut?: number;
  timing?: string;
  triggerClose?: boolean;
  isOpen?: boolean;
};

type TooltipControllerState = {
  actualPlacement: TooltipPlacement;
  animate: boolean;
  arrowParams: ArrowParams | null;
  divStyle: CSSProperties;
  isOpen: boolean;
  timeOutID: number | null;
  trigger: boolean;
};

const getElementRect = (element: Element | null) => {
  if (!element) {
    return {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    };
  }

  const rect = element.getBoundingClientRect();
  if (rect) {
    const { height, left, top, width } = rect;
    return {
      height,
      width,
      x: left,
      y: top,
    };
  }

  return {
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  };
};

export const TooltipController = memo<TooltipControllerProps>(
  ({
    align = 'middle',
    animation,
    autoPlacement = true,
    children,
    closeOnClick,
    detect,
    duration,
    isOpen: isOpenProp,
    placement = 'top',
    properties = [],
    returnState,
    targetMargin,
    timeOut,
    timing,
    triggerClose = false,
  }) => {
    const [state, setState] = useState<TooltipControllerState>({
      actualPlacement: 'top',
      animate: false,
      arrowParams: placementToArrowParams[placement],
      divStyle: {
        left: 0,
        pointerEvents: detect === 'hover' ? 'none' : 'inherit',
        position: 'absolute',
        top: 0,
        transform: '',
        transitionDuration: duration,
        transitionProperty: properties.join(' '),
        transitionTimingFunction: timing,
        zIndex: 1400, // TODO: get from theme
      },
      isOpen: false,
      timeOutID: null,
      trigger: triggerClose,
    });
    const tooltipRef = useRef<HTMLSpanElement | null>(null);
    const targetRef = useRef(null);
    const timeOutIDRef = useRef<NodeJS.Timeout | null>(null);
    const isOpen = isOpenProp ?? state.isOpen;

    const { animate: stateAnimate, trigger: stateTrigger } = state;

    const clearCloseTimeOutFunc = useCallback(() => {
      if (timeOutIDRef.current) {
        clearTimeout(timeOutIDRef.current);
      }
    }, []);

    useEffect(() => {
      return clearCloseTimeOutFunc;
    }, [clearCloseTimeOutFunc]);

    const closeMenu = useCallback(() => {
      if (timeOut && detect !== 'hover-interact') {
        clearCloseTimeOutFunc();
      }

      if (!isOpen) {
        return;
      }

      // Turn off the animation > removes the specific animation class.
      if (animation) {
        // Return menu status.
        if (returnState) {
          setState((prevState) =>
            create(prevState, (draft) => {
              draft.animate = false;
            }),
          );
          returnState(false);
        } else {
          setState((prevState) =>
            create(prevState, (draft) => {
              draft.animate = false;
            }),
          );
        }

        // Remove pointer events from all active DIVs.
        if (tooltipRef.current) {
          tooltipRef.current.style.pointerEvents = 'none';
        }
      } else if (returnState) {
        setState((prevState) =>
          create(prevState, (draft) => {
            draft.isOpen = false;
          }),
        );
        returnState(false);
      } else {
        setState((prevState) =>
          create(prevState, (draft) => {
            draft.isOpen = false;
          }),
        );
      }
    }, [
      isOpen,
      timeOut,
      detect,
      animation,
      returnState,
      clearCloseTimeOutFunc,
    ]);

    const closeTimeOutFunc = useCallback(() => {
      const timeOutID = setTimeout(closeMenu, timeOut);
      timeOutIDRef.current = timeOutID;
    }, [closeMenu, timeOut]);

    const openMenu = useCallback(
      (e: Event) => {
        e.preventDefault();
        clearCloseTimeOutFunc();

        if (stateAnimate) {
          return;
        }
        if (isOpen) {
          return;
        }

        setState((prevState) =>
          create(prevState, (draft) => {
            draft.isOpen = true;
          }),
        );
        if (returnState) {
          returnState(true);
        }

        // Turn on the animation > adds the specific animation class.
        if (animation) {
          setTimeout(() => {
            setState((prevState) =>
              create(prevState, (draft) => {
                draft.animate = true;
              }),
            );
            if (tooltipRef.current) {
              // Add the pointer events to all active DIVs.
              tooltipRef.current.style.pointerEvents = 'auto';
            }
          }, 0);
        }
      },
      [stateAnimate, isOpen, animation, returnState, clearCloseTimeOutFunc],
    );

    const updatePosition = useCallback(() => {
      let actualPlacement = placement;
      if (!isOpen || !targetRef.current) {
        return;
      }

      const targetRect = getElementRect(targetRef.current);
      if (targetMargin) {
        targetRect.x -= targetMargin.x;
        targetRect.y -= targetMargin.y;
        targetRect.width += 2 * targetMargin.x;
        targetRect.height += 2 * targetMargin.y;
      }

      const tooltipRect = getElementRect(tooltipRef.current);
      const tooltipSize = {
        height: tooltipRect.height,
        width: tooltipRect.width,
      };

      const windowSize = {
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth,
      };

      if (autoPlacement) {
        actualPlacement = improveTooltipPlacement(
          targetRect,
          windowSize,
          tooltipSize,
          actualPlacement,
          align,
        );
      }

      const position = calcTooltipPosition(
        targetRect,
        tooltipSize,
        actualPlacement,
        align,
      );
      const arrowParams = getArrowParams(
        targetRect,
        position,
        tooltipSize,
        actualPlacement,
        align,
      );

      setState((prevState) =>
        create(prevState, (draft) => {
          draft.arrowParams = arrowParams;
          draft.actualPlacement = actualPlacement;
          draft.divStyle.transform = `translate(${position.x}px, ${position.y}px)`;
        }),
      );
    }, [align, autoPlacement, isOpen, placement, targetMargin]);

    useEffect(() => {
      if (triggerClose !== stateTrigger) {
        setState((prevState) =>
          create(prevState, (draft) => {
            draft.trigger = triggerClose;
          }),
        );
        closeMenu();
      }

      const timeout = setTimeout(() => {
        if (animation) {
          if (stateAnimate) {
            window.addEventListener('click', closeMenu);
            window.addEventListener('touchend', closeMenu);
          } else {
            window.removeEventListener('click', closeMenu);
            window.removeEventListener('touchend', closeMenu);
          }
        } else if (isOpen) {
          if (detect !== 'hover-interact' || closeOnClick) {
            window.addEventListener('click', closeMenu);
            window.addEventListener('touchend', closeMenu);
          }
        } else if (detect !== 'hover-interact' || closeOnClick) {
          window.removeEventListener('click', closeMenu);
          window.removeEventListener('touchend', closeMenu);
        }
      }, 0);

      return () => {
        clearTimeout(timeout);
        window.removeEventListener('click', closeMenu);
        window.removeEventListener('touchend', closeMenu);
      };
    }, [
      isOpen,
      stateTrigger,
      stateAnimate,
      animation,
      closeMenu,
      triggerClose,
      detect,
      closeOnClick,
    ]);

    useEffect(() => {
      if (isOpen && timeOut && detect !== 'hover-interact') {
        closeTimeOutFunc();
      }
    }, [isOpen, closeMenu, timeOut, detect, closeTimeOutFunc]);

    useLayoutEffect(() => {
      updatePosition();
      const resizeObserver = new ResizeObserver(updatePosition);
      const target = tooltipRef.current;
      if (target) {
        resizeObserver.observe(target);
        return () => {
          resizeObserver.disconnect();
        };
      }
    }, [updatePosition]);

    const stopPropagation = useCallback(
      (e: UIEvent<HTMLSpanElement>) => e.stopPropagation(),
      [],
    );

    const inputChildren = Children.map(children, (child: any) => {
      if (child.type === Trigger) {
        return cloneElement(child, {
          closeMenu,
          detect,
          openMenu,
          ref: targetRef,
          timeOutFunc: closeTimeOutFunc,
        });
      }

      return (
        isOpen &&
        ReactDOM.createPortal(
          <span
            ref={tooltipRef}
            className={state.animate ? `${animation}` : 'animate-tooltip'}
            onClick={closeOnClick ? undefined : stopPropagation}
            onMouseEnter={
              detect === 'hover-interact' ? clearCloseTimeOutFunc : undefined
            }
            onMouseLeave={
              detect === 'hover-interact' ? closeTimeOutFunc : undefined
            }
            onTouchEnd={closeOnClick ? undefined : stopPropagation}
            style={state.divStyle}
          >
            {cloneElement(child, { arrowParams: state.arrowParams })}
          </span>,
          document.body,
        )
      );
    });

    return <Fragment>{inputChildren}</Fragment>;
  },
);
