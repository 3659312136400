import { PropsWithChildren } from 'react';

import { cn } from '~/utils';

import { ArrowParams } from './tooltipPlacement';

const TooltipArrow = ({
  direction = 'down',
  left,
  top,
  transform,
}: ArrowParams) => (
  <div
    className={cn(
      'absolute z-30 block size-0 border-[10px] border-transparent',
      {
        'border-b-tigaSurface-darkest': direction === 'up',
        'border-l-tigaSurface-darkest': direction === 'right',
        'border-t-tigaSurface-darkest': direction === 'down',
        'border-r-tigaSurface-darkest': direction === 'left',
      },
    )}
    style={{
      left,
      top,
      transform,
    }}
  />
);

type P = PropsWithChildren<{
  arrowParams: ArrowParams;
}> &
  ComponentStyling;

export const TooltipComponent = ({
  arrowParams,
  children,
  className,
  style,
}: P) => {
  return (
    <div
      className={cn(
        'pointer-events-none relative z-30 max-w-60 rounded-md bg-tigaSurface-darkest px-4 py-2 text-center text-sm text-tigaText-lightest shadow-md',
        className,
      )}
      style={style}
      role="tooltip"
    >
      {children}
      <TooltipArrow {...arrowParams} />
    </div>
  );
};
