'use client';

import { Icon } from '~/ui/atoms';
import { Button } from '~/ui/molecules/Button';

type P = {
  className?: string;
  index: number;
  length: number;
  move: (from: number, to: number) => void;
};

export const ListSortButtons = ({ className = '', index, length, move }: P) => {
  const moveUp = () => move(index, index - 1);
  const moveDown = () => move(index, index + 1);
  const isFirstChild = index === 0;
  const isLastChild = index === length - 1;

  return (
    <div className={className}>
      <Button
        buttonStyle="secondary"
        className="px-2"
        disabled={isFirstChild}
        onClick={moveUp}
        size="small"
        icon={<Icon metaphor="arrowUp" />}
        title={'Move this item up one position in the list'}
      />
      <Button
        buttonStyle="secondary"
        className="px-2"
        disabled={isLastChild}
        icon={<Icon metaphor="arrowDown" />}
        onClick={moveDown}
        size="small"
        title={'Move this item down one position in the list'}
      />
    </div>
  );
};
