export type Point = {
  x: number;
  y: number;
};

type Size = {
  height: number;
  width: number;
};

export type Rect = {
  height: number;
  width: number;
  x: number;
  y: number;
};

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right';
export type TooltipAlign = 'start' | 'middle' | 'end';

const alignToMultiplier = {
  end: 1,
  middle: 0.5,
  start: 0,
};

export const calcTooltipPosition = (
  targetRect: Rect,
  tooltipSize: Size,
  placement: TooltipPlacement,
  align: TooltipAlign,
) => {
  const alignMultiplier = alignToMultiplier[align];

  switch (placement) {
    case 'left':
      return {
        x: targetRect.x - tooltipSize.width,
        y:
          targetRect.y +
          (targetRect.height - tooltipSize.height) * alignMultiplier,
      };

    case 'right':
      return {
        x: targetRect.x + targetRect.width,
        y:
          targetRect.y +
          (targetRect.height - tooltipSize.height) * alignMultiplier,
      };

    case 'top':
      return {
        x:
          targetRect.x +
          (targetRect.width - tooltipSize.width) * alignMultiplier,
        y: targetRect.y - tooltipSize.height,
      };

    case 'bottom':
    default:
      return {
        x:
          targetRect.x +
          (targetRect.width - tooltipSize.width) * alignMultiplier,
        y: targetRect.y + targetRect.height,
      };
  }
};

export const visibleProportionOfRect = (targetRect: Rect, sourceRect: Rect) => {
  // Calculate intersection points.
  const left = Math.max(sourceRect.x, targetRect.x);
  const top = Math.max(sourceRect.y, targetRect.y);
  const right = Math.min(
    sourceRect.x + sourceRect.width,
    targetRect.x + targetRect.width,
  );
  const bottom = Math.min(
    sourceRect.y + sourceRect.height,
    targetRect.y + targetRect.height,
  );
  const width = right - left;
  const height = bottom - top;

  if (width < 0 || height < 0) {
    return 0;
  }

  const targetRectArea = targetRect.width * targetRect.height;
  const intersectionArea = width * height;

  return Math.round((intersectionArea / targetRectArea) * 100) / 100;
};

export const visibleTooltipProportion = (
  targetRect: Rect,
  windowSize: Size,
  tooltipSize: Size,
  placement: TooltipPlacement,
  align: TooltipAlign,
) => {
  const windowRect = { x: 0, y: 0, ...windowSize };
  const position = calcTooltipPosition(
    targetRect,
    tooltipSize,
    placement,
    align,
  );
  const tooltipRect = { ...position, ...tooltipSize };
  const proportion = visibleProportionOfRect(tooltipRect, windowRect);

  return proportion;
};

const oppositePlacements = {
  bottom: 'top' as const,
  left: 'right' as const,
  right: 'left' as const,
  top: 'bottom' as const,
};

export const improveTooltipPlacement = (
  targetRect: Rect,
  windowSize: Size,
  tooltipSize: Size,
  placement: TooltipPlacement,
  align: TooltipAlign,
) => {
  const proportion = visibleTooltipProportion(
    targetRect,
    windowSize,
    tooltipSize,
    placement,
    align,
  );
  if (proportion === 1) {
    return placement;
  }

  const oppositePlacement = oppositePlacements[placement];
  const oppositeProportion = visibleTooltipProportion(
    targetRect,
    windowSize,
    tooltipSize,
    oppositePlacement,
    align,
  );
  if (oppositeProportion === 1) {
    return oppositePlacement;
  }

  let orthogonalPlacement: TooltipPlacement;
  if (placement === 'left' || placement === 'right') {
    const spaceTop = targetRect.y;
    const spaceBottom = windowSize.height - targetRect.y - targetRect.height;
    orthogonalPlacement = spaceTop >= spaceBottom ? 'top' : 'bottom';
  } else {
    const spaceLeft = targetRect.x;
    const spaceRight = windowSize.width - targetRect.x - targetRect.width;
    orthogonalPlacement = spaceLeft >= spaceRight ? 'left' : 'right';
  }

  const orthogonalProportion = visibleTooltipProportion(
    targetRect,
    windowSize,
    tooltipSize,
    orthogonalPlacement,
    align,
  );
  if (orthogonalProportion === 1) {
    return orthogonalPlacement;
  }

  // Worst case scenario: tooltip is never fully visible.
  // Choose the placement that maximizes the visible area.
  const maxVisiblePortion = Math.max(
    proportion,
    oppositeProportion,
    orthogonalProportion,
  );
  if (maxVisiblePortion === proportion) {
    return placement;
  } else if (maxVisiblePortion === oppositeProportion) {
    return oppositePlacement;
  }

  return orthogonalPlacement;
};

export type ArrowParams = {
  direction: 'up' | 'down' | 'left' | 'right';
  left: string;
  top: string;
  transform: string;
};

export const placementToArrowParams: Record<TooltipPlacement, ArrowParams> = {
  bottom: {
    direction: 'up',
    left: '50%',
    top: '0%',
    transform: 'translateX(-50%) translateY(-100%)',
  },
  left: {
    direction: 'right',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  right: {
    direction: 'left',
    left: '0%',
    top: '50%',
    transform: 'translateY(-50%) translateX(-100%)',
  },
  top: {
    direction: 'down',
    left: '50%',
    top: '100%',
    transform: 'translateX(-50%)',
  },
};

/* eslint-disable max-params */
export const getArrowParams = (
  targetRect: Rect,
  tooltipPosition: Point,
  tooltipSize: Size,
  placement: TooltipPlacement,
  align: TooltipAlign,
): ArrowParams => {
  const arrowParams = placementToArrowParams[placement];

  if (align === 'middle') {
    return {
      ...arrowParams,
    };
  }

  const clip = align === 'start' ? Math.min : Math.max;

  if (['bottom', 'top'].includes(placement)) {
    const tooltipX = tooltipPosition.x + tooltipSize.width * 0.5;
    const targetX = targetRect.x + targetRect.width * 0.5;

    return {
      ...arrowParams,
      left: `${clip(tooltipX, targetX) - tooltipPosition.x}px`,
    };
  }

  const tooltipY = tooltipPosition.y + tooltipSize.height * 0.5;
  const targetY = targetRect.y + targetRect.height * 0.5;

  return {
    ...arrowParams,
    top: `${clip(tooltipY, targetY) - tooltipPosition.y}px`,
  };
};
