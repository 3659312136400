export enum ConfigKeys {
  ApiUrl = 'apiUrl',
  Environment = 'environment',
  MuiLicenseKey = 'muiLicenseKey',
  RedirectUrl = 'redirectUrl',
  SgwUrl = 'sgwUrl',
  StagingApiUrl = 'stagingApiUrl',
}

/**
 * A TypeScript object with all config information,
 * to make it easier to get the desired env value.
 *
 * @property {string} apiUrl - The base URL of the API for the current environment.
 * @property {string} environment - The environment in which the app is running.
 * @property {string} muiLicenseKey - The key for the Material UI license.
 * @property {string} redirectUrl - The base URL for redirecting on authentication.
 * @property {string} sgwUrl - The base URL of the SGW.
 * @property {string} stagingApiUrl - The base URL of the staging API.
 *
 */
export const Config: Record<ConfigKeys, string> = {
  [ConfigKeys.ApiUrl]: import.meta.env.VITE_ENV_API_URL ?? '',
  [ConfigKeys.Environment]: import.meta.env.MODE,
  [ConfigKeys.MuiLicenseKey]: import.meta.env.VITE_MUI_LICENSE_KEY ?? '',
  [ConfigKeys.RedirectUrl]: import.meta.env.VITE_ENV_REDIRECT_URL ?? '',
  [ConfigKeys.SgwUrl]: import.meta.env.VITE_ENV_SGW_URL ?? '',
  [ConfigKeys.StagingApiUrl]: 'https://app.staging.vestigas.com/api/v1',
};
