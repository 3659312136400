import '~/ui/styles/index.css';
import '~/ui/styles/legacy.css';
// Initialize i18next
import '~/utils/i18n';

import { ThemeProvider } from '@mui/material/styles';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'oidc-react';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { theme } from '~/ui/styles';

import { HotkeysProvider } from '~/hooks';

import { AppInitializer } from './modules/app-initializer';
import { router } from './modules/router';
import store from './redux/store';
import AuthService from './services/auth.service';
import { initMuiPro } from './services/thirdParty.service';
import ToastService from './services/toast.service';

initMuiPro();

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      ToastService.error(`Something went wrong: ${error.message}`);
    },
  }),
});

export const App = () => {
  const { t } = useTranslation();

  // Set locale for update notice somewhere.
  // window.pluginWebUpdateNotice_.setLocale('de_DE');

  return (
    <>
      <Helmet>
        <title>{t('app.metaTitle')}</title>
        <meta name="description" content={t('app.metaDescription')} />
      </Helmet>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <AuthProvider {...AuthService.oidcConfig}>
            <QueryClientProvider client={queryClient}>
              <HotkeysProvider>
                <AppInitializer>
                  <RouterProvider router={router} />
                </AppInitializer>
                <Toaster
                  toastOptions={{
                    className: '',
                    style: {
                      borderRadius: '5px',
                      padding: '5px 0px 5px 5px',
                      maxWidth: '60vw',
                      zIndex: 1400, // = MUI theme zIndex.snackbar
                    },
                  }}
                  containerStyle={{ zIndex: 1400 }}
                  position="top-right"
                />
              </HotkeysProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AuthProvider>
        </ThemeProvider>
      </ReduxProvider>
    </>
  );
};
