import { unique as uniqueLib } from 'remeda';

/**
 * Joins non-falsy elements of an array with a specified separator.
 * Keeps 0 as a non-falsy element.
 *
 * @example ['foo', null, 'bar', undefined, 'baz', '', 0] => 'foo bar baz 0'
 *
 * @param {Array<string | number | null | undefined>} array - The array to join.
 * @param {string} [joinSequence=' '] - The separator to use between elements. Defaults to a space.
 * @return {string} The joined string.
 */
export const joinElements = (
  array: (string | number | null | undefined)[] = [],
  joinSequence = ' ',
): string => {
  return array.filter((e) => e != null && e !== '').join(joinSequence);
};

export const unique = (array: unknown[]) => uniqueLib(array);

// returns all values of array1 that are not in array2 (return = array1 - array2)
export const subtract = (array1, array2) => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return [];
  }

  return array1.filter((x) => !array2.includes(x));
};

export const getDifference = (oldArray, newArray) => {
  const removedValues = subtract(oldArray, newArray);
  const addedValues = subtract(newArray, oldArray);

  return [removedValues, addedValues];
};

export const sortByKey = (array, key, desc = false) => {
  try {
    return [...array].sort((a, b) => {
      const x = a?.[key];
      const y = b?.[key];

      if (x === undefined || y === undefined) {
        return 0;
      }

      // TODO: alphabetical sorting works like this:
      // .toSorted((a, b) => a?.[key].localeCompare(a?.[key]));

      if (x < y) return desc ? 1 : -1;
      if (x > y) return desc ? -1 : 1;
      return 0;
    });
  } catch (e) {
    return array ?? [];
  }
};

// remove a specific key from an array
export const removeFromArray = (array, key) => {
  if (array && key) {
    return array.filter((x) => x !== key);
  }

  return array ?? [];
};

export const removeByKey = (array, key, value) => {
  const newArray = [...array];
  const index = array.findIndex((item) => item[key] === value);
  newArray.splice(index, 1);

  return newArray;
};

export const replaceKeyInArray = (array, oldKey, newKey) => {
  if (array && oldKey && newKey) {
    return array.map((x) => (x === oldKey ? newKey : x));
  }

  return array ?? [];
};
