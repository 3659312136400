export const API_PATH = {
  ACCESS_CONTROL: '/access_control',
  COMPANY: '/company',
  COMPANY_ACCOUNT: '/company_account',
  COMPANY_ADDRESS: '/company_address',
  DATA_EXCHANGE: '/data_exchange',
  ROLE: '/admin/role',
  ROOT: '/',
  USERS: '/user',
  VADMIN: '/vadmin',
} as const;
