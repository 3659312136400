import { createTheme } from '@mui/material/styles';
import { deDE } from '@mui/x-data-grid';

export const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: '#1976d214',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(0, 0, 0, 0.84)',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: '#03a9f4',
          fontWeight: 'font-bold',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Remove uppercase transformation
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1rem !important',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
        },
      },
    },
    ...deDE.components, // Localize DataGrid
  },
});
