import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '../constants/loadingState';

const dataExchangesSlice = createSlice({
  name: 'dataExchanges',

  initialState: {
    dataExchanges: [],
    dataExchangesLoading: LOADING_STATE.NOT_LOADED,
    dataExchangeCompanies: [],
    dataExchangeCompaniesLoading: LOADING_STATE.NOT_LOADED,
  },

  reducers: (create) => ({
    replaceDataExchanges: create.reducer((state, param) => {
      const { payload } = param;

      state.dataExchanges = [...payload];
      state.dataExchangesLoading = LOADING_STATE.SUCCEEDED;
    }),

    setDataExchangesLoading: create.reducer((state, param) => {
      const { payload } = param;

      state.dataExchangesLoading = payload;
    }),

    replaceDataExchangeCompanies: create.reducer((state, param) => {
      const { payload } = param;

      state.dataExchangeCompanies = [...payload];
      state.dataExchangeCompaniesLoading = LOADING_STATE.SUCCEEDED;
    }),

    setDataExchangeCompaniesLoading: create.reducer((state, param) => {
      const { payload } = param;

      state.dataExchangeCompaniesLoading = payload;
    }),
  }),
});

const { actions, reducer } = dataExchangesSlice;
export const {
  replaceDataExchangeCompanies,
  replaceDataExchanges,
  setDataExchangeCompaniesLoading,
  setDataExchangesLoading,
} = actions;
export default reducer;
