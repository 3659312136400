import axios from 'axios';
import ms from 'ms';
import { UserManager } from 'oidc-react';
import qs from 'qs';

import { i18n, toSnakeCase } from '~/utils';
import { promiseHandler } from '~/utils/promiseHandler';

import { Config } from '../Config';
import ToastService from './toast.service';

export const getJwtPayload = () => {
  const jwt = localStorage.getItem('accessToken');

  if (!jwt) {
    throw new Error('Failed to load JWT from local storage.');
  }

  const payload = jwt?.split('.')[1];

  return JSON.parse(atob(payload));
};

const TOKENS = ['refreshToken', 'accessToken', 'idToken'] as const;

class AuthService {
  constructor() {
    this.oidcConfig = {
      onSignIn: async (user) => {
        this.saveTokens(user);

        // Redirect directly to the initially called page so that the oidc state ("?state=...") is removed from the url.
        // This is important mainly due to two reasons:
        // 1. Keeping the oidc state in the url led to a bug where the page couldn't be loaded when people where using the url with the state as a bookmark.
        // 2. The oidc state in the url is used to determine in which state of the login flow the page currently is.
        const [url] = window.location.href.split('?');
        window.location.href = url;
      },
      onSignOut: () => {
        this.removeTokens();
      },

      authority: 'https://login.vestigas.com/auth/realms/vestigas/',
      clientId: 'mobile_apps',
      redirectUri: window.location.href, // Redirect to the page initially called by the user.
      responseType: 'code',
      scope: 'openid offline_access',
    };

    this.userManager = new UserManager({
      ...this.oidcConfig,
      client_id: this.oidcConfig.clientId,
      redirect_uri: this.oidcConfig.redirectUri,
    });

    //this.logoutToast = null;
    this.shouldLogout = false;
  }

  refreshTokens = async () => {
    const [response, err] = await promiseHandler(this.loadRefreshedTokens());

    if (err) {
      console.error('Failed to refresh tokens.', err);

      if (this.shouldLogout) {
        // Don't trigger logout again while it is in progress already.
        return;
      }

      ToastService.warning(i18n.t('common.auth.sessionExpired'));

      this.shouldLogout = true;

      setTimeout(() => {
        if (this.shouldLogout) {
          this.logout();
        }
      }, ms('10s'));

      return Promise.reject(err);
    }

    this.saveTokens(response.data);

    return response.data.access_token;
  };

  async loadRefreshedTokens() {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken)
      throw new Error(
        'Failed to retrieve refresh token from local storage. refresh token: ' +
          refreshToken,
      );

    const body = qs.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: this.oidcConfig.clientId,
    });

    return axios.post(tokenUrl, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  saveTokens(data) {
    TOKENS.forEach((token) => {
      const tokenData = data[toSnakeCase(token)!];

      if (tokenData) {
        localStorage.setItem(token, tokenData);
      }
    });
  }

  removeTokens() {
    TOKENS.forEach((token) => {
      localStorage.removeItem(token);
    });
  }

  logout() {
    this.removeTokens();
    this.userManager.signoutRedirect({
      post_logout_redirect_uri: Config.redirectUrl + '/?logout=true',
      // post_logout_redirect_uri requires either client_id or id_token_hint to be set (requirement from
      // keycloak/oidc). Id_token_hint will be set by oidc-react if everything works, but if login fails and
      // authprovider runs in an exception, it isn't set (or any other option when no id token was obtained. So we
      // set client_id in all cases to prevent keycloak error screens.
      extraQueryParams: { client_id: 'mobile_apps' },
    });
  }

  getJwtPayload(jwt) {
    const payload = jwt?.split('.')[1];
    return JSON.parse(atob(payload));
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  getIdToken() {
    return localStorage.getItem('idToken');
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  // To track whether this redirect came from logout.
  // In this case, the user shouldn't be logged out again as this would cause an infinite loop of page reloads
  isLogoutUrl(url: string) {
    return url.includes('?logout=true');
  }
}

const AuthServiceInstance = new AuthService();

export default AuthServiceInstance;
