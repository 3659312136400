import { configureStore } from '@reduxjs/toolkit';

import companiesReducer from './companiesSlice';
import companyAccountsReducer from './companyAccountsSlice';
import companyIdentifiersReducer from './companyIdentifiersSlice';
import dataExchangesReducer from './dataExchangesSlice';
import usersReducer from './usersSlice';
import wizardReducer from './wizardSlice';

const store = configureStore({
  reducer: {
    companyAccounts: companyAccountsReducer,
    companies: companiesReducer,
    companyIdentifiers: companyIdentifiersReducer,
    users: usersReducer,
    wizard: wizardReducer,
    dataExchanges: dataExchangesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
