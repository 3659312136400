import { Address } from '~/types/address';
import {
  Account,
  Agreement,
  CO2Emission,
  Consignment,
  Delivery,
  DeliveryNote,
  Distance,
  Execution,
  Header,
  LegalOrganization,
  Note,
  Party,
  Settlement,
  TradeAddress,
  TradeContact,
  Transaction,
  UniversalCommunication,
} from '~/types/deliveryNote';

export const UNIVERSAL_COMMUNICATION_STRUCTURE: UniversalCommunication = {
  completeNumber: undefined,
  email: undefined,
  type: undefined,
};

export const TRADE_CONTACT_STRUCTURE: TradeContact = {
  departmentName: undefined,
  jobTitle: undefined,
  personName: undefined,
  universalCommunication: UNIVERSAL_COMMUNICATION_STRUCTURE,
};

export const ADDRESS_STRUCTURE: Address = {
  buildingNumber: undefined,
  city: undefined,
  country: undefined,
  postCode: undefined,
  streetName: undefined,
};

export const LEGAL_ORGANIZATION_STRUCTURE: LegalOrganization = {
  address: ADDRESS_STRUCTURE,
  companyAccountId: undefined,
  id: null,
  issuerAssignedId: undefined,
  name: undefined,
  universalCommunication: UNIVERSAL_COMMUNICATION_STRUCTURE,
};

export const PARTY_STRUCTURE: Party = {
  id: null,
  legalOrganization: LEGAL_ORGANIZATION_STRUCTURE,
  tradeContact: TRADE_CONTACT_STRUCTURE,
};

export const TRADE_ADDRESS_STRUCTURE: TradeAddress = {
  ...ADDRESS_STRUCTURE,
  id: null,
  issuerAssignedId: undefined,
  lineOne: undefined,
};

export const CONSIGNMENT_STRUCTURE: Consignment = {
  carrier: PARTY_STRUCTURE,
  movement: {
    driver: TRADE_CONTACT_STRUCTURE,
    freightForwarder: PARTY_STRUCTURE,
    means: undefined,
    registrationId: [
      {
        country: undefined,
        id: null,
      },
    ],
  },
};

export const MEASURE_STRUCTURE = {
  measure: undefined,
  value: undefined,
};

export const EXECUTION_STRUCTURE: Execution = {
  arrived: undefined,
  arrivedPlanned: undefined,
  beginExecution: undefined,
  beginExecutionPlanned: undefined,
  beginLoading: undefined,
  beginLoadingPlanned: undefined,
  beginUnloading: undefined,
  beginUnloadingPlanned: undefined,
  co2Emission: MEASURE_STRUCTURE as CO2Emission,
  co2EmissionPlanned: MEASURE_STRUCTURE as CO2Emission,
  departure: undefined,
  departurePlanned: undefined,
  distance: MEASURE_STRUCTURE as Distance,
  distancePlanned: MEASURE_STRUCTURE as Distance,
  endExecution: undefined,
  endExecutionPlanned: undefined,
  endLoading: undefined,
  endLoadingPlanned: undefined,
  endUnloading: undefined,
  endUnloadingPlanned: undefined,
  waitingTime: undefined,
};

export const NOTE_STRUCTURE: Note = {
  note: {
    content: {
      comment: undefined,
      measure: undefined,
      time: undefined,
    },
  },
  type: undefined,
};

export const ACCOUNT_STRUCTURE: Account = {
  id: null,
  name: undefined,
  typeCode: undefined,
};

export const SETTLEMENT_STRUCTURE: Settlement = {
  payableAccount: ACCOUNT_STRUCTURE,
  purchaseAccount: ACCOUNT_STRUCTURE,
  salesAccount: ACCOUNT_STRUCTURE,
};

export const DELIVERY_STRUCTURE: Delivery = {
  execution: EXECUTION_STRUCTURE,
  note: [NOTE_STRUCTURE],
  shipFrom: {
    ...PARTY_STRUCTURE,
    consignment: CONSIGNMENT_STRUCTURE,
    tradeAddress: TRADE_ADDRESS_STRUCTURE,
  },
  shipTo: {
    ...PARTY_STRUCTURE,
    tradeAddress: TRADE_ADDRESS_STRUCTURE,
  },
  shippingMarks: null,
};

export const AGREEMENT_STRUCTURE: Agreement = {
  buyer: PARTY_STRUCTURE,
  deliveryTerms: {
    code: undefined,
  },
  seller: PARTY_STRUCTURE,
  trader: PARTY_STRUCTURE,
};

export const HEADER_STRUCTURE: Header = {
  additionalPartyData: {},
  date: undefined,
  id: null,
  issuer: PARTY_STRUCTURE,
};

export const TRANSACTION_STRUCTURE: Transaction = {
  agreement: AGREEMENT_STRUCTURE,
  delivery: DELIVERY_STRUCTURE,
  logisticsPackage: [
    {
      id: '1', // logisticsPackage ALWAYS has only one item with all the data
      lineItem: [],
    },
  ],
  settlement: SETTLEMENT_STRUCTURE,
};

/**
 * Object structure of a delivery note and its components.
 */
export const DELIVERY_NOTE_STRUCTURE: DeliveryNote = {
  context: 'DELNTE',
  header: HEADER_STRUCTURE,
  transaction: TRANSACTION_STRUCTURE,
};
