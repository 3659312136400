import urlcat from 'urlcat';

import { UUID } from '~/types/common';

/**
 * Paths for all endpoints of the VESTIGAS API used in the application.
 */
export const ENDPOINT = {
  ASSET_REPARSING: {
    DELETE_REPARSING_ASSET: (reparsingId: UUID): string =>
      urlcat('vadmin/asset_reparsing/:reparsingId', {
        reparsingId,
      }),
    GET_ALL: 'vadmin/asset_reparsing/all',
    GET_ORIGINAL_DLN_FILE: (filename: string): string =>
      urlcat('vadmin/asset_reparsing/original_dln_file', {
        filename,
      }),
    REPARSE_ASSET: (reparsingId: UUID): string =>
      urlcat('vadmin/asset_reparsing/:reparsingId/parse', {
        reparsingId,
      }),
    UPDATE_ORIGINAL_DLN_DATA: (reparsingId: UUID): string =>
      urlcat('vadmin/asset_reparsing/:reparsingId/update_original_dln_data', {
        reparsingId,
      }),
  },
  COMPANY: {
    CREATE_IDENTIFIER: 'company/identifier',
    GET_ALL: 'company/all',
    GET_SUPPLIER_ALERT_CONFIGS: 'company/sending_companies',
  },
  COMPANY_ADDRESS: {
    CREATE: 'company_address',
  },
  COMPANY_INFORMATION: {
    GET_ALL: 'company_information/all',
  },
  DATA_EXCHANGES: {
    GET_ALL: 'data_exchange/all',
  },
  USER: {
    GET_ALL: 'user/all',
  },
} as const;
