import { useNProgress } from '@tanem/react-nprogress';
import { useNavigation } from 'react-router-dom';

import { Bar } from './Bar';
import { Container } from './Container';

/**
 * Renders an animated progress indicator bar whenever a navigation is in progress.
 *
 * The current location should be provided as key to the component.
 * This ensures a new NProgress instance is created every time the location changes,
 * see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key.
 *
 * Usage:
 *
 * `<RoutingProgressIndicator key={location.href} />`
 *
 * @return {JSX.Element} The RoutingProgressIndicator component
 */
export const RoutingProgressIndicator = () => {
  const navigation = useNavigation();

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: navigation.state === 'loading',
  });

  const zIndex = 1401; // z-index is set to render the component above Material UI components.

  return (
    <Container
      animationDuration={animationDuration}
      isFinished={isFinished}
      zIndex={zIndex}
    >
      <Bar
        animationDuration={animationDuration}
        progress={progress}
        zIndex={zIndex}
      />
    </Container>
  );
};
