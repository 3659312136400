/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import type { QueryClient } from '@tanstack/react-query';
import ms from 'ms';

import { AssetType, ReparsingTaskType } from '~/modules/asset-reparsing/types';

import { ENDPOINT } from '~/constants';
import { vestigasApi } from '~/services/kyClient';
import { i18n } from '~/utils';

import { mapReparsingAssetsData } from './mapReparsingAssetsQueryData';

/**
 * Picks data from the list of assets to render the tasks list.
 * The assets are already transformed from the raw API response in the `mapReparsingAssetsData` function.
 */
const mapTasks = (assets: ReturnType<typeof mapReparsingAssetsData>[] = []) =>
  assets?.map(({ assetId, header }) => ({
    assetId,
    searchString: [assetId, header.id]
      .filter(Boolean)
      .map((e) => String(e).toLowerCase())
      .join(';'),
    dlnNumber: header.id,
  }));

/**
 * Filters an array of assets based on the presence of provided task types.
 * If no task types are provided, the original array of assets is returned.
 *
 * @param {AssetType[]} assets - The array of assets to filter. Defaults to an empty array if not provided.
 * @param {ReparsingTaskType[]} taskTypes - The array of task types to filter by. If not provided or empty, the original array of assets is returned.
 * @return {AssetType[]} The filtered array of assets.
 */
const filterReparsingTaskType = (
  assets: AssetType[] = [],
  taskTypes?: ReparsingTaskType[],
) => {
  if (!taskTypes?.length) {
    return assets;
  }

  return assets.filter(({ taskType }) => taskTypes.includes(taskType));
};

/**
 * Gets all assets saved for potential reparsing.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/VESTIGAS-Admin/operation/get_assets_for_reparsing_vadmin_asset_reparsing_all_get
 */
const getAllReparsingAssets = async () => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.ASSET_REPARSING.GET_ALL)
      .json<{
        items?: object[]; // FIXME: type this properly as reparsing asset!
      }>();

    return response.items ?? [];
  } catch (error) {
    // Attach custom message to error object.
    const customError = new Error(
      i18n.t('assetReparsing.query.reparsingAssets.error.generic'),
    );

    console.error('Error fetching assets for reparsing:', error);

    // Re-throw the error to propagate it to the calling function.
    throw customError;
  }
};

type ReparsingAssetsQueryOptions = {
  reparsingTaskTypes?: ReparsingTaskType[];
};

/**
 * Function to generate the react-query config for fetching reparsing assets.
 * Reusable within the useQuery hook and for the react-router loader.
 *
 * @return {Object} The query object containing query key, query function, refetch interval, assets, and tasks.
 */
const reparsingAssetsQuery = (
  options: ReparsingAssetsQueryOptions = {},
): object => {
  const { reparsingTaskTypes, ...rest } = options;

  // TODO: use query-key-factory instead of selects? https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
  return {
    queryKey: ['reparsingAssets'],
    queryFn: getAllReparsingAssets,
    refetchInterval: ms('1m'),
    select: (data) => {
      const assets = mapReparsingAssetsData(data);

      const filteredAssets = filterReparsingTaskType(
        assets,
        reparsingTaskTypes,
      );

      return {
        assets: filteredAssets,
        tasks: mapTasks(filteredAssets),
      };
    },
    ...rest,
  };
};

/**
 * A custom hook to fetch all reparsing assets.
 * Uses the `useQuery` hook from `@tanstack/react-query` internally.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/VESTIGAS-Admin/operation/get_assets_for_reparsing_vadmin_asset_reparsing_all_get
 *
 * @return {UseQueryResult<any[]>} The result of the query, which is an array of reparsing assets.
 */
export const useQueryReparsingAssets = (
  options: object & ReparsingAssetsQueryOptions = {},
) => useQuery(reparsingAssetsQuery(options));

/**
 * react-router loader function for reparsingAssetsQuery.
 * Allows already fetching the data when the route is visited.
 * The useQuery hook of useQueryReparsingAssets can only run when the component is mounted, which happens much later.
 *
 * @param {QueryClient} queryClient - The query client for fetching data.
 * @return {Promise<any>} The data fetched or returned from the query.
 */
export const assetReparsingLoader = (queryClient: QueryClient) => async () => {
  const query = reparsingAssetsQuery();

  // Return data from cache or fetch it.
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
