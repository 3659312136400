import { PropsWithChildren } from 'react';

type P = PropsWithChildren<{
  isFinished: boolean;
  animationDuration: number;
  zIndex?: number;
}>;

export const Container = ({
  animationDuration,
  children,
  isFinished,
  zIndex = 1401,
}: P) => (
  <div
    style={{
      opacity: isFinished ? 0 : 1,
      pointerEvents: 'none',
      transition: `opacity ${animationDuration}ms linear`,
      position: 'fixed',
      top: 0,
      width: '100%',
      left: 0,
      zIndex,
    }}
  >
    {children}
  </div>
);
