import { PropsWithChildren } from 'react';

import { cn } from '~/utils/tailwind';

type P = PropsWithChildren<{
  vAlign?: 'center' | 'top';
}> &
  ComponentStyling;

export const FormRow = ({
  children,
  className,
  style,
  vAlign = 'center',
}: P) => (
  <div
    className={cn(
      'relative flex w-full flex-wrap gap-x-4 gap-y-2',
      {
        'items-center': vAlign === 'center',
        'items-start': vAlign === 'top',
      },
      className,
    )}
    style={style}
  >
    {children}
  </div>
);
