import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { Suspense, memo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { RoutingProgressIndicator } from '~/modules/router';

import { MainNav } from '~/ui/molecules/MainNav';
import { Spinner } from '~/ui/molecules/Spinner';

import { HeaderBar } from './HeaderBar';

const PREFIX = 'AppLayout';

const classes = {
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => {
  const drawerWidth = {
    open: theme.spacing(30),
    closed: theme.spacing(7),
  };

  return {
    [`& .${classes.appBar}`]: {
      transition: theme.transitions.create(['width', 'margin'], {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      zIndex: theme.zIndex.drawer + 1,
    },
    [`& .${classes.appBarShift}`]: {
      marginLeft: `${drawerWidth.open} !important`,
      transition: theme.transitions.create(['width', 'margin'], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: `calc(100% - ${drawerWidth.open}) !important`,
    },
    [`& .${classes.drawerPaper}`]: {
      position: 'relative',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      whiteSpace: 'nowrap',
      width: drawerWidth.open,
      zIndex: theme.zIndex.appBar + 1,
    },
    [`& .${classes.drawerPaperClose}`]: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: drawerWidth.closed,
    },
    [`& .${classes.appBarSpacer}`]: theme.mixins.toolbar,
  };
});

export const AppLayout = memo(() => {
  const location = useLocation();

  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Root className="w-full">
      <RoutingProgressIndicator
        key={
          // @ts-expect-error // TODO: look up react-router-dom types
          location.href
        }
      />
      <Suspense fallback={<Spinner />}>
        <div className="flex h-full w-full">
          <HeaderBar
            className={clsx([classes.appBar, open && classes.appBarShift])}
            mainNavOpen={open}
          />
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx([
                classes.drawerPaper,
                !open && classes.drawerPaperClose,
              ]),
            }}
            open={open}
          >
            <MainNav isOpen={open} toggleDrawer={toggleDrawer} />
          </Drawer>
          <main
            className={clsx(['h-full w-full flex-1 overflow-auto px-4 pb-4'])}
          >
            <div className={classes.appBarSpacer} />
            <Outlet />
          </main>
        </div>
      </Suspense>
    </Root>
  );
});
