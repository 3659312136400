import cloneDeep from 'lodash/cloneDeep';

import Company from './Company';

export default class CompanyAccount {
  constructor() {
    this.id = '';
    this.name = '';
    this.syncWithStaging = false;
    this.companies = [];
    this.baseOrganisationalUnitId = null;
    this.featureFlags = {
      customer_subscription: false,
      user_in_dln_history: true,
      sentry_google_analytics_disabled: false,
      create_delivery_note: false,
      suppliers_kanban: false,
      no_privacy_statement: false,
      concrete_diary: false,
      create_demo_dlns: false,
      client_portal: false,
      module_invoice_restriction: false,
      package_basic_restriction: false,
      deactivated_company_account: false,
      product_analytics_tools: false,
      disable_invoices: false,
      service_notes: false,
      disable_gps: false,
      access_archive: false,
      load_dlns_amount: CompanyAccount.DEFAULT_LOAD_DLNS_AMOUNT,
    };
    this.data = {};
  }

  init(companyAccount) {
    this.id = companyAccount?.id ?? '';
    this.name = companyAccount?.name ?? '';
    this.syncWithStaging = companyAccount?.syncWithStaging;
    this.companies = companyAccount?.companies?.map((company) => {
      const newCompany = new Company();
      newCompany.init(company);
      return newCompany;
    });
    this.baseOrganisationalUnitId = companyAccount?.base_ou_id;
    this.featureFlags = {
      customer_subscription:
        companyAccount?.data?.feature_flags?.customer_subscription ?? false,
      user_in_dln_history:
        companyAccount?.data?.feature_flags?.user_in_dln_history ?? false,
      sentry_google_analytics_disabled:
        companyAccount?.data?.feature_flags?.sentry_google_analytics_disabled ??
        false,
      create_delivery_note:
        companyAccount?.data?.feature_flags?.create_delivery_note ?? false,
      suppliers_kanban:
        companyAccount?.data?.feature_flags?.suppliers_kanban ?? false,
      no_privacy_statement:
        companyAccount?.data?.feature_flags?.no_privacy_statement ?? false,
      concrete_diary:
        companyAccount?.data?.feature_flags?.concrete_diary ?? false,
      create_demo_dlns:
        companyAccount?.data?.feature_flags?.create_demo_dlns ?? false,
      client_portal:
        companyAccount?.data?.feature_flags?.client_portal ?? false,
      module_invoice_restriction:
        companyAccount?.data?.feature_flags?.module_invoice_restriction ??
        false,
      package_basic_restriction:
        companyAccount?.data?.feature_flags?.package_basic_restriction ?? false,
      deactivated_company_account:
        companyAccount?.data?.feature_flags?.deactivated_company_account ??
        false,
      product_analytics_tools:
        companyAccount?.data?.feature_flags?.product_analytics_tools ?? false,
      disable_invoices:
        companyAccount?.data?.feature_flags?.disable_invoices ?? false,
      service_notes:
        companyAccount?.data?.feature_flags?.service_notes ?? false,
      disable_gps: companyAccount?.data?.feature_flags?.disable_gps ?? false,
      access_archive:
        companyAccount?.data?.feature_flags?.access_archive ?? false,
      load_dlns_amount:
        companyAccount?.data?.feature_flags?.load_dlns_amount ??
        CompanyAccount.DEFAULT_LOAD_DLNS_AMOUNT,
    };
    this.data = companyAccount?.data;
  }

  clone(companyAccount) {
    this.id = companyAccount?.id;
    this.name = companyAccount?.name;
    this.syncWithStaging = companyAccount?.syncWithStaging;
    this.companies = companyAccount?.companies;
    this.baseOrganisationalUnitId = companyAccount?.baseOrganisationalUnitId;
    this.featureFlags = companyAccount?.featureFlags
      ? cloneDeep(companyAccount.featureFlags)
      : this.featureFlags;
    this.data = companyAccount?.data
      ? cloneDeep(companyAccount.data)
      : this.data;
  }

  setDefaultValues() {
    this.syncWithStaging = true;
  }

  static DEFAULT_LOAD_DLNS_AMOUNT = 6_000;
  static MAX_LOAD_DLNS_AMOUNT = 50_000;
}
